.skeleton {
    height: inherit;
    background-color: #4d4c4c;
    margin: 12px 0;
    border-radius: 4px;
}

.skeleton.title {
    height: 28px;
    margin-bottom: 10px;
}

.skeleton.subtitle {
    height: 12px;
    margin-bottom: 16px;
}

.skeleton.image {
    height: 195px;
}

.skeleton.text {
    height: 12px;
}


.skeleton.width100 {
    width: 100%;
}

.skeleton.width50 {
    width: 50%;
}

.logo{
    margin: 0;
    height: 100%;
}

.width35 {
    width: 35%;
}

.width15 {
    width: 15%;
}

.skeleton.profile-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.cards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -10px;
}

.cards .card {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
}

.cards .card>div {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.articles.cards .card {
    width: 25%;
    height: 250px;
}

.themes.cards {
    overflow: auto;
    flex-wrap: nowrap;
}

.themes.cards .card {
    width: 18%;
    min-width: 18%;
    height: 360px;
}

/* animation */
@keyframes pulse {
    50% {
        opacity: .5;
    }
}

.animatepulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.loader {
    margin-bottom: 54px;
}

.loader:nth-last-child() {
    margin-bottom: 0;
}

.loader .title {
    width: 20% !important;
}

/* Fullscreen css begin */
.fullScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -12px;
}

/* Fullscreen css end */

@media (max-width: 1300px) {
    .themes.cards .card {
        width: 20%;
        min-width: 20%;
    }
}

@media (max-width: 1100px) {
    .themes.cards .card {
        width: 22%;
        min-width: 22%;
    }
}

@media (max-width: 1200px) {
    .title.width35 {
        width: 24%;
    }

    .articles.cards .card {
        width: 33.33%;
        height: 180px;
    }

    .themes.cards .card {
        width: 24%;
        min-width: 24%;
    }
}

@media (max-width: 800px) {
    .width35 {
        width: 75%;
    }

    .articles.cards .card {
        width: 50%;
        height: 160px;
    }

    .themes.cards .card {
        width: 35%;
        min-width: 35%;
    }
}
.center {
    max-width: 1520px;
    padding: 0 60px;
    margin: auto;
}

@media(max-width: 1440px) {
    .center {
        padding: 0 32px;
    }
}

@media (max-width: 550px) {
    .center {
        padding: 0 30px;
    }
}

@media (max-width: 420px) {
    .center {
        padding: 0 16px;
    }
}
:root {
    --layout-bg: #000;
    --layout-bg-revert: #fff;
    --layout-text: #fff;
    --button-bg: #EDF959;
    --button-text: #000;
    --link-hover: #EDF959;
    --chart-opacity: 0.3;
    --header-border: transparent;
    --footer-border: #999;
    --input-bg: #fff;
    --input-text: #161F64;
    --input-border: #fff;
    --checkbox-border: #EDF959;
    --back-color: #EDF959;
    --input-error: #f00;
    --input-focus: #EDF959;
    --section-bg: #121212;
    --section-border: #2e2e2e;
    --faq-bg: #272727;
    --faq-text-bg: #1b1b1b;
    --resource-bg: #FF9E00;
    --layout-bg-opacity: #ffffff0d;
    --on-surface-disabled: #ffffff61;
    --on-surface-medium-emphasis: #ffffff99;
    --on-primary-medium-emphasis: #000000bd;
    --secondary-color: #EDF959;
    --placeholder-bg: #4a4a4a;
    --outline: #ffffff1f;
    --link-border: #ffffff17;
    --opacity-bg: #ffffff4d;
    --dropdown-shadow: #bbb5b533;
    --multiselect-hover: #111;
    --radio-select: #fff161;
    --wireframe-black: #141414;
    --grey: #f0f0f0;
    --border: #373737;
    --sky: #1fe3ff;
    --nude: #FFE7D2;
    --dark-green: #003A30;
    --dark-ui-outline: #ffffff1f;
    --sushi-green: #8CAC39;
    --chalke-pink: #E8ABC7;
    --chalke-light-green: #79B7B5;
    --grey-10: rgba(255, 255, 255, 0.12);
    --light-green: #127E64;
    --maroon: #831C23;
    --dark-blue: #0F1B2D;
    --chalke-off-white: #FFFAF6;
    --white-80: rgba(255, 255, 255, 0.87);
    --white-60: rgba(255, 255, 255, 0.60);
    --white-30: rgba(255, 255, 255, 0.30);
    --grey-90: rgba(255, 255, 255, 0.09);
}

:root.lightTheme {
    --layout-bg: #fff;
    --layout-bg-revert: #000;
    --layout-text: #003A30;
    --button-bg: #003A30;
    --button-text: #fff;
    --link-hover: #000;
    --chart-opacity: 1;
    --header-border: #F0F0F0;
    --footer-border: #F0F0F0;
    --input-text: #161F64;
    --input-bg: transparent;
    --input-border: #003A30;
    --checkbox-border: #003A30;
    --back-color: #1de3ff;
    --input-error: #f00;
    --input-focus: #000;
    --section-bg: #FFFAF6;
    --section-border: #f6f6f6;
    --faq-bg: #f0f0f0;
    --layout-bg-opacity: #0000000d;
    --on-surface-disabled: #00000061;
    --on-surface-medium-emphasis: #00000099;
    --on-primary-medium-emphasis: #ffffffbd;
    --secondary-color: #161F64;
    --placeholder-bg: #4a4a4a;
    --link-border: #00000017;
    --opacity-bg: #0000004d;
    --dropdown-shadow: #ffffff33;
    --multiselect-hover: #f0f0f0;
    --radio-select: #000161;
}

* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    background: var(--section-bg);
    transition: background-color 0.4s ease-in-out;
    color: var(--layout-text);
}

html.overflow-hidden body {
    overflow: hidden;
}

body,
input,
textarea,
select,
.Toastify .Toastify__toast-theme--light {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

input,
textarea,
select {
    color: var(--input-text);
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

img {
    max-width: 100%;
    border-style: none;
    width: auto;
    height: auto;
}

p {
    line-height: 1.65;
}

label {
    cursor: pointer;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}

a:active {
    background-color: transparent;
}

select,
map,
area,
a,
button {
    border: 0px;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

input[type=text],
input[type=password],
textarea {
    text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input::-ms-clear {
    display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
    display: none;
}

input,
textarea,
select {
    border: 0;
    outline: none;
    line-height: normal;
    -webkit-appearance: none;
    border-radius: 0;
}

textarea {
    resize: none;
}

body>iframe {
    display: none !important;
}

.darkTheme img[data-theme="light"],
.lightTheme img[data-theme="dark"] {
    display: none;
}

body .button.play_white {
    color: var(--layout-bg);
    background-color: var(--layout-bg-revert);
    border-color: var(--layout-bg-revert);
    border-radius: 4px;
}

body .button.more_info_trans {
    background-color: rgba(255, 255, 255, 0.3);
    color: var(--layout-bg-revert);
    border-color: var(--layout-bg-revert);
    border-radius: 4px;
}

body .save_btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    border: 1px solid var(--on-surface-disabled);
    font-weight: 700;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--on-surface-disabled);
    color: var(--layout-bg-revert);
}

body .save_btn:hover {
    border-color: var(--button-bg);
}

body .button.play_white:hover {
    border-color: var(--layout-bg-revert);
    color: var(--layout-bg-revert);
}

body .button.more_info_trans:hover {
    color: var(--layout-bg-revert);
}

.section {
    position: relative;
    padding: 30px;
}

.content {
    max-width: 1400px;
    margin: auto;
    position: relative;
}

.p_disable {
    opacity: 0.5;
    pointer-events: none;
}

.loader.last-section {
    padding-bottom: 80px;
}

.first-section {
    padding-top: 80px !important;
}

/* Toastify css begin*/
.Toastify .Toastify__toast-theme--light {
    background-color: var(--button-bg);
    color: var(--button-text);
    font-weight: 700;
}

.Toastify__toast-container {
    --toastify-z-index: 100000;
}

/* Toastify css end*/

@media (max-width: 1200px) {
    .section {
        padding: 26px;
    }
}

@media (max-width: 800px) {
    .section {
        padding: 16px;
    }
}
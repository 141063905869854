@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-blackitalic.841efb35.eot);
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url(/static/media/montserrat-blackitalic.841efb35.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-blackitalic.8822a482.woff2) format('woff2'),
        url(/static/media/montserrat-blackitalic.7c00476c.woff) format('woff'),
        url(/static/media/montserrat-blackitalic.5d4cb82d.ttf) format('truetype'),
        url(/static/media/montserrat-blackitalic.d3d86b44.svg#Mulish-BoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-italic.85d3450c.eot);
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url(/static/media/montserrat-italic.85d3450c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-italic.7c25b227.woff2) format('woff2'),
        url(/static/media/montserrat-italic.189e38b5.woff) format('woff'),
        url(/static/media/montserrat-italic.eb695a62.ttf) format('truetype'),
        url(/static/media/montserrat-italic.138ced81.svg#Mulish-Italic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-extralight.951b78fe.eot);
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url(/static/media/montserrat-extralight.951b78fe.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-extralight.3e70c245.woff2) format('woff2'),
        url(/static/media/montserrat-extralight.ad944f8f.woff) format('woff'),
        url(/static/media/montserrat-extralight.ac41aaa0.ttf) format('truetype'),
        url(/static/media/montserrat-extralight.b622c10a.svg#Mulish-ExtraLight) format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-blackitalic.841efb35.eot);
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url(/static/media/montserrat-blackitalic.841efb35.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-blackitalic.8822a482.woff2) format('woff2'),
        url(/static/media/montserrat-blackitalic.7c00476c.woff) format('woff'),
        url(/static/media/montserrat-blackitalic.5d4cb82d.ttf) format('truetype'),
        url(/static/media/montserrat-blackitalic.d3d86b44.svg#Mulish-BlackItalic) format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-extralightitalic.2edbd0b6.eot);
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
        url(/static/media/montserrat-extralightitalic.2edbd0b6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-extralightitalic.3e714820.woff2) format('woff2'),
        url(/static/media/montserrat-extralightitalic.8a5d323e.woff) format('woff'),
        url(/static/media/montserrat-extralightitalic.81f36c92.ttf) format('truetype'),
        url(/static/media/montserrat-extralightitalic.64941ccd.svg#Mulish-ExtraLightItalic) format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-extrabolditalic.9ffb5afc.eot);
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url(/static/media/montserrat-extrabolditalic.9ffb5afc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-extrabolditalic.28272d7a.woff2) format('woff2'),
        url(/static/media/montserrat-extrabolditalic.f34000a1.woff) format('woff'),
        url(/static/media/montserrat-extrabolditalic.5bd3e1e2.ttf) format('truetype'),
        url(/static/media/montserrat-extrabolditalic.a8efe1a4.svg#Mulish-ExtraBoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-black.52c62238.eot);
    src: local('Montserrat Black'), local('Montserrat-Black'),
        url(/static/media/montserrat-black.52c62238.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-black.1f949e98.woff2) format('woff2'),
        url(/static/media/montserrat-black.ac71c603.woff) format('woff'),
        url(/static/media/montserrat-black.adc1bcf6.ttf) format('truetype'),
        url(/static/media/montserrat-black.0a7423ec.svg#Mulish-Black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-extrabold.b3ad1290.eot);
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
        url(/static/media/montserrat-extrabold.b3ad1290.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-extrabold.d6e48cab.woff2) format('woff2'),
        url(/static/media/montserrat-extrabold.f6be4a70.woff) format('woff'),
        url(/static/media/montserrat-extrabold.e6ddad11.ttf) format('truetype'),
        url(/static/media/montserrat-extrabold.55097007.svg#Mulish-ExtraBold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-bold.ac4b56d6.eot);
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url(/static/media/montserrat-bold.ac4b56d6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-bold.44cf7152.woff2) format('woff2'),
        url(/static/media/montserrat-bold.e52ad762.woff) format('woff'),
        url(/static/media/montserrat-bold.7fb88034.ttf) format('truetype'),
        url(/static/media/montserrat-bold.75a29fa0.svg#Mulish-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-lightitalic.c91a49fc.eot);
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
        url(/static/media/montserrat-lightitalic.c91a49fc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-lightitalic.bf7e545c.woff2) format('woff2'),
        url(/static/media/montserrat-lightitalic.4bce425a.woff) format('woff'),
        url(/static/media/montserrat-lightitalic.d1b6799c.ttf) format('truetype'),
        url(/static/media/montserrat-lightitalic.e23fba53.svg#Mulish-LightItalic) format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-light.721d1060.eot);
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url(/static/media/montserrat-light.721d1060.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-light.4ddeebde.woff2) format('woff2'),
        url(/static/media/montserrat-light.eb44bcff.woff) format('woff'),
        url(/static/media/montserrat-light.0332b893.ttf) format('truetype'),
        url(/static/media/montserrat-light.d9ff22ed.svg#Mulish-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-mediumitalic.09beb30b.eot);
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
        url(/static/media/montserrat-mediumitalic.09beb30b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-mediumitalic.850988ad.woff2) format('woff2'),
        url(/static/media/montserrat-mediumitalic.e77a9079.woff) format('woff'),
        url(/static/media/montserrat-mediumitalic.1a2c91e1.ttf) format('truetype'),
        url(/static/media/montserrat-mediumitalic.e44a8c56.svg#Mulish-MediumItalic) format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-medium.d72f78ab.eot);
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
        url(/static/media/montserrat-medium.d72f78ab.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-medium.cfe7907c.woff2) format('woff2'),
        url(/static/media/montserrat-medium.17cce0d6.woff) format('woff'),
        url(/static/media/montserrat-medium.8b658c64.ttf) format('truetype'),
        url(/static/media/montserrat-medium.fbf56933.svg#Mulish-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-semibold.318aad25.eot);
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url(/static/media/montserrat-semibold.318aad25.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-semibold.c9cb3eab.woff2) format('woff2'),
        url(/static/media/montserrat-semibold.de0e12d3.woff) format('woff'),
        url(/static/media/montserrat-semibold.5bf2715d.ttf) format('truetype'),
        url(/static/media/montserrat-semibold.ecdcc4e7.svg#Mulish-SemiBold) format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-regular.2945cfd3.eot);
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(/static/media/montserrat-regular.2945cfd3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-regular.3a8d111e.woff2) format('woff2'),
        url(/static/media/montserrat-regular.bf91291f.woff) format('woff'),
        url(/static/media/montserrat-regular.62f335e1.ttf) format('truetype'),
        url(/static/media/montserrat-regular.5744a8cf.svg#Mulish-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/montserrat-semibolditalic.715734db.eot);
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
        url(/static/media/montserrat-semibolditalic.715734db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/montserrat-semibolditalic.4121510e.woff2) format('woff2'),
        url(/static/media/montserrat-semibolditalic.24e64a96.woff) format('woff'),
        url(/static/media/montserrat-semibolditalic.c1b78963.ttf) format('truetype'),
        url(/static/media/montserrat-semibolditalic.1d66a1af.svg#Mulish-SemiBoldItalic) format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}



/* Mullish */
@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-BoldItalic.9dc832e8.eot);
    src: local('Mulish Bold Italic'), local('Mulish-BoldItalic'),
        url(/static/media/Mulish-BoldItalic.9dc832e8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-BoldItalic.c3577776.woff2) format('woff2'),
        url(/static/media/Mulish-BoldItalic.457675de.woff) format('woff'),
        url(/static/media/Mulish-BoldItalic.d30cd6af.ttf) format('truetype'),
        url(/static/media/Mulish-BoldItalic.17bd1c10.svg#Mulish-BoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Italic.f1c353f2.eot);
    src: local('Mulish Italic'), local('Mulish-Italic'),
        url(/static/media/Mulish-Italic.f1c353f2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Italic.3f51cccd.woff2) format('woff2'),
        url(/static/media/Mulish-Italic.dc50a9fb.woff) format('woff'),
        url(/static/media/Mulish-Italic.ca8c3872.ttf) format('truetype'),
        url(/static/media/Mulish-Italic.94bafde0.svg#Mulish-Italic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-ExtraLight.6ae3df07.eot);
    src: local('Mulish ExtraLight'), local('Mulish-ExtraLight'),
        url(/static/media/Mulish-ExtraLight.6ae3df07.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-ExtraLight.0e131dae.woff2) format('woff2'),
        url(/static/media/Mulish-ExtraLight.6d32e781.woff) format('woff'),
        url(/static/media/Mulish-ExtraLight.3b574096.ttf) format('truetype'),
        url(/static/media/Mulish-ExtraLight.fced0964.svg#Mulish-ExtraLight) format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-BlackItalic.6be5ac45.eot);
    src: local('Mulish Black Italic'), local('Mulish-BlackItalic'),
        url(/static/media/Mulish-BlackItalic.6be5ac45.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-BlackItalic.fd11c9bc.woff2) format('woff2'),
        url(/static/media/Mulish-BlackItalic.6a873d40.woff) format('woff'),
        url(/static/media/Mulish-BlackItalic.7d1318a9.ttf) format('truetype'),
        url(/static/media/Mulish-BlackItalic.36477fff.svg#Mulish-BlackItalic) format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-ExtraLightItalic.5186053f.eot);
    src: local('Mulish ExtraLight Italic'), local('Mulish-ExtraLightItalic'),
        url(/static/media/Mulish-ExtraLightItalic.5186053f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-ExtraLightItalic.2858596a.woff2) format('woff2'),
        url(/static/media/Mulish-ExtraLightItalic.bc6031b0.woff) format('woff'),
        url(/static/media/Mulish-ExtraLightItalic.b40fb75c.ttf) format('truetype'),
        url(/static/media/Mulish-ExtraLightItalic.636eecb3.svg#Mulish-ExtraLightItalic) format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-ExtraBoldItalic.c72c824f.eot);
    src: local('Mulish ExtraBold Italic'), local('Mulish-ExtraBoldItalic'),
        url(/static/media/Mulish-ExtraBoldItalic.c72c824f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-ExtraBoldItalic.3634ccf8.woff2) format('woff2'),
        url(/static/media/Mulish-ExtraBoldItalic.f4fe53e4.woff) format('woff'),
        url(/static/media/Mulish-ExtraBoldItalic.ec8289ed.ttf) format('truetype'),
        url(/static/media/Mulish-ExtraBoldItalic.7ddb6a05.svg#Mulish-ExtraBoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Black.4832d131.eot);
    src: local('Mulish Black'), local('Mulish-Black'),
        url(/static/media/Mulish-Black.4832d131.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Black.e4afec6a.woff2) format('woff2'),
        url(/static/media/Mulish-Black.8d3cde12.woff) format('woff'),
        url(/static/media/Mulish-Black.8d9fea7d.ttf) format('truetype'),
        url(/static/media/Mulish-Black.f5f3acb7.svg#Mulish-Black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-ExtraBold.f5bb405b.eot);
    src: local('Mulish ExtraBold'), local('Mulish-ExtraBold'),
        url(/static/media/Mulish-ExtraBold.f5bb405b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-ExtraBold.e06f2526.woff2) format('woff2'),
        url(/static/media/Mulish-ExtraBold.7cd1dc41.woff) format('woff'),
        url(/static/media/Mulish-ExtraBold.591893d8.ttf) format('truetype'),
        url(/static/media/Mulish-ExtraBold.4cc738a9.svg#Mulish-ExtraBold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Bold.2301fd64.eot);
    src: local('Mulish Bold'), local('Mulish-Bold'),
        url(/static/media/Mulish-Bold.2301fd64.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Bold.ccb5e338.woff2) format('woff2'),
        url(/static/media/Mulish-Bold.021d815c.woff) format('woff'),
        url(/static/media/Mulish-Bold.19b87ab5.ttf) format('truetype'),
        url(/static/media/Mulish-Bold.cada4a39.svg#Mulish-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-LightItalic.1b8d34ba.eot);
    src: local('Mulish Light Italic'), local('Mulish-LightItalic'),
        url(/static/media/Mulish-LightItalic.1b8d34ba.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-LightItalic.6374d0fb.woff2) format('woff2'),
        url(/static/media/Mulish-LightItalic.3c0f43c1.woff) format('woff'),
        url(/static/media/Mulish-LightItalic.fda3e67c.ttf) format('truetype'),
        url(/static/media/Mulish-LightItalic.4c568315.svg#Mulish-LightItalic) format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Light.27627c7f.eot);
    src: local('Mulish Light'), local('Mulish-Light'),
        url(/static/media/Mulish-Light.27627c7f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Light.6b24d85a.woff2) format('woff2'),
        url(/static/media/Mulish-Light.b0c8a67b.woff) format('woff'),
        url(/static/media/Mulish-Light.3788b812.ttf) format('truetype'),
        url(/static/media/Mulish-Light.ecc8852b.svg#Mulish-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-MediumItalic.27fca952.eot);
    src: local('Mulish Medium Italic'), local('Mulish-MediumItalic'),
        url(/static/media/Mulish-MediumItalic.27fca952.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-MediumItalic.28033eba.woff2) format('woff2'),
        url(/static/media/Mulish-MediumItalic.44c0d3c0.woff) format('woff'),
        url(/static/media/Mulish-MediumItalic.dfae4fc0.ttf) format('truetype'),
        url(/static/media/Mulish-MediumItalic.2dbbbdde.svg#Mulish-MediumItalic) format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Medium.c5b86896.eot);
    src: local('Mulish Medium'), local('Mulish-Medium'),
        url(/static/media/Mulish-Medium.c5b86896.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Medium.af798480.woff2) format('woff2'),
        url(/static/media/Mulish-Medium.93d0d7ea.woff) format('woff'),
        url(/static/media/Mulish-Medium.11464221.ttf) format('truetype'),
        url(/static/media/Mulish-Medium.7f677c2e.svg#Mulish-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-SemiBold.265f60c5.eot);
    src: local('Mulish SemiBold'), local('Mulish-SemiBold'),
        url(/static/media/Mulish-SemiBold.265f60c5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-SemiBold.9638566d.woff2) format('woff2'),
        url(/static/media/Mulish-SemiBold.cba5ff3e.woff) format('woff'),
        url(/static/media/Mulish-SemiBold.09c029a7.ttf) format('truetype'),
        url(/static/media/Mulish-SemiBold.392c8727.svg#Mulish-SemiBold) format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-Regular.7b6e363c.eot);
    src: local('Mulish Regular'), local('Mulish-Regular'),
        url(/static/media/Mulish-Regular.7b6e363c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-Regular.7a2ba295.woff2) format('woff2'),
        url(/static/media/Mulish-Regular.896767d7.woff) format('woff'),
        url(/static/media/Mulish-Regular.718663a3.ttf) format('truetype'),
        url(/static/media/Mulish-Regular.85009e01.svg#Mulish-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url(/static/media/Mulish-SemiBoldItalic.eb92afc9.eot);
    src: local('Mulish SemiBold Italic'), local('Mulish-SemiBoldItalic'),
        url(/static/media/Mulish-SemiBoldItalic.eb92afc9.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Mulish-SemiBoldItalic.b9e70e8a.woff2) format('woff2'),
        url(/static/media/Mulish-SemiBoldItalic.64f74ac1.woff) format('woff'),
        url(/static/media/Mulish-SemiBoldItalic.44c1d17a.ttf) format('truetype'),
        url(/static/media/Mulish-SemiBoldItalic.61c6c650.svg#Mulish-SemiBoldItalic) format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-BoldItalic.bb8e9829.eot);
    src: local('CooperHewitt-BoldItalic'),
        url(/static/media/CooperHewitt-BoldItalic.bb8e9829.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-BoldItalic.94ee07db.woff2) format('woff2'),
        url(/static/media/CooperHewitt-BoldItalic.9201bd6a.woff) format('woff'),
        url(/static/media/CooperHewitt-BoldItalic.748e5f60.ttf) format('truetype'),
        url(/static/media/CooperHewitt-BoldItalic.5b085107.svg#CooperHewitt-BoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url(/static/media/CooperHewitt-BookItalic.9b8b37ec.eot);
    src: local('CooperHewitt-BookItalic'),
        url(/static/media/CooperHewitt-BookItalic.9b8b37ec.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-BookItalic.cd1544a1.woff2) format('woff2'),
        url(/static/media/CooperHewitt-BookItalic.8159d727.woff) format('woff'),
        url(/static/media/CooperHewitt-BookItalic.f363f63c.ttf) format('truetype'),
        url(/static/media/CooperHewitt-BookItalic.55e26918.svg#CooperHewitt-BookItalic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Light.59a90f7a.eot);
    src: local('CooperHewitt-Light'),
        url(/static/media/CooperHewitt-Light.59a90f7a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Light.63df2689.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Light.d82c7a74.woff) format('woff'),
        url(/static/media/CooperHewitt-Light.6b495d3f.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Light.64c79988.svg#CooperHewitt-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Heavy.47b75a92.eot);
    src: local('CooperHewitt-Heavy'),
        url(/static/media/CooperHewitt-Heavy.47b75a92.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Heavy.6ad3a412.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Heavy.1313c30a.woff) format('woff'),
        url(/static/media/CooperHewitt-Heavy.257e4ce0.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Heavy.0e7b0696.svg#CooperHewitt-Heavy) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-HeavyItalic.a1ce8889.eot);
    src: local('CooperHewitt-HeavyItalic'),
        url(/static/media/CooperHewitt-HeavyItalic.a1ce8889.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-HeavyItalic.c75f0e0b.woff2) format('woff2'),
        url(/static/media/CooperHewitt-HeavyItalic.70a912ad.woff) format('woff'),
        url(/static/media/CooperHewitt-HeavyItalic.5cbeb88d.ttf) format('truetype'),
        url(/static/media/CooperHewitt-HeavyItalic.65e11cbc.svg#CooperHewitt-HeavyItalic) format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Bold.a820fa58.eot);
    src: local('CooperHewitt-Bold'),
        url(/static/media/CooperHewitt-Bold.a820fa58.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Bold.9cbc16bc.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Bold.98d42900.woff) format('woff'),
        url(/static/media/CooperHewitt-Bold.718de945.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Bold.1d9cd87f.svg#CooperHewitt-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url(/static/media/CooperHewitt-Book.92b1e9c6.eot);
    src: local('CooperHewitt-Book'),
        url(/static/media/CooperHewitt-Book.92b1e9c6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Book.84d0237e.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Book.5f3dc8f2.woff) format('woff'),
        url(/static/media/CooperHewitt-Book.366d7e64.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Book.0f70931b.svg#CooperHewitt-Book) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Medium.c60eb9d1.eot);
    src: local('CooperHewitt-Medium'),
        url(/static/media/CooperHewitt-Medium.c60eb9d1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Medium.0282d0ab.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Medium.adae8cab.woff) format('woff'),
        url(/static/media/CooperHewitt-Medium.7907310f.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Medium.a7874ef4.svg#CooperHewitt-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-LightItalic.0344966d.eot);
    src: local('CooperHewitt-LightItalic'),
        url(/static/media/CooperHewitt-LightItalic.0344966d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-LightItalic.5e6a3107.woff2) format('woff2'),
        url(/static/media/CooperHewitt-LightItalic.66edd9c4.woff) format('woff'),
        url(/static/media/CooperHewitt-LightItalic.c6986118.ttf) format('truetype'),
        url(/static/media/CooperHewitt-LightItalic.96bfa085.svg#CooperHewitt-LightItalic) format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-MediumItalic.0f254fac.eot);
    src: local('CooperHewitt-MediumItalic'),
        url(/static/media/CooperHewitt-MediumItalic.0f254fac.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-MediumItalic.ded4cbca.woff2) format('woff2'),
        url(/static/media/CooperHewitt-MediumItalic.26a5100e.woff) format('woff'),
        url(/static/media/CooperHewitt-MediumItalic.0dfc1d36.ttf) format('truetype'),
        url(/static/media/CooperHewitt-MediumItalic.0b72f7eb.svg#CooperHewitt-MediumItalic) format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-SemiboldItalic.104d59db.eot);
    src: local('CooperHewitt-SemiboldItalic'),
        url(/static/media/CooperHewitt-SemiboldItalic.104d59db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-SemiboldItalic.8a58ee8e.woff2) format('woff2'),
        url(/static/media/CooperHewitt-SemiboldItalic.571ff12d.woff) format('woff'),
        url(/static/media/CooperHewitt-SemiboldItalic.822663fd.ttf) format('truetype'),
        url(/static/media/CooperHewitt-SemiboldItalic.c2438078.svg#CooperHewitt-SemiboldItalic) format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-ThinItalic.c1197db3.eot);
    src: local('CooperHewitt-ThinItalic'),
        url(/static/media/CooperHewitt-ThinItalic.c1197db3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-ThinItalic.b89f35a9.woff2) format('woff2'),
        url(/static/media/CooperHewitt-ThinItalic.4b186ab1.woff) format('woff'),
        url(/static/media/CooperHewitt-ThinItalic.f5566e96.ttf) format('truetype'),
        url(/static/media/CooperHewitt-ThinItalic.a56a4262.svg#CooperHewitt-ThinItalic) format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Semibold.f5ed1ab0.eot);
    src: local('CooperHewitt-Semibold'),
        url(/static/media/CooperHewitt-Semibold.f5ed1ab0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Semibold.4fda373e.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Semibold.52b6edab.woff) format('woff'),
        url(/static/media/CooperHewitt-Semibold.fcba8989.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Semibold.ffdab47d.svg#CooperHewitt-Semibold) format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url(/static/media/CooperHewitt-Thin.faa35e29.eot);
    src: local('CooperHewitt-Thin'),
        url(/static/media/CooperHewitt-Thin.faa35e29.eot?#iefix) format('embedded-opentype'),
        url(/static/media/CooperHewitt-Thin.688283d8.woff2) format('woff2'),
        url(/static/media/CooperHewitt-Thin.d619cb4f.woff) format('woff'),
        url(/static/media/CooperHewitt-Thin.6848a090.ttf) format('truetype'),
        url(/static/media/CooperHewitt-Thin.55bd5703.svg#CooperHewitt-Thin) format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
:root {
    --layout-bg: #000;
    --layout-bg-revert: #fff;
    --layout-text: #fff;
    --button-bg: #EDF959;
    --button-text: #000;
    --link-hover: #EDF959;
    --chart-opacity: 0.3;
    --header-border: transparent;
    --footer-border: #999;
    --input-bg: #fff;
    --input-text: #161F64;
    --input-border: #fff;
    --checkbox-border: #EDF959;
    --back-color: #EDF959;
    --input-error: #f00;
    --input-focus: #EDF959;
    --section-bg: #121212;
    --section-border: #2e2e2e;
    --faq-bg: #272727;
    --faq-text-bg: #1b1b1b;
    --resource-bg: #FF9E00;
    --layout-bg-opacity: #ffffff0d;
    --on-surface-disabled: #ffffff61;
    --on-surface-medium-emphasis: #ffffff99;
    --on-primary-medium-emphasis: #000000bd;
    --secondary-color: #EDF959;
    --placeholder-bg: #4a4a4a;
    --outline: #ffffff1f;
    --link-border: #ffffff17;
    --opacity-bg: #ffffff4d;
    --dropdown-shadow: #bbb5b533;
    --multiselect-hover: #111;
    --radio-select: #fff161;
    --wireframe-black: #141414;
    --grey: #f0f0f0;
    --border: #373737;
    --sky: #1fe3ff;
    --nude: #FFE7D2;
    --dark-green: #003A30;
    --dark-ui-outline: #ffffff1f;
    --sushi-green: #8CAC39;
    --chalke-pink: #E8ABC7;
    --chalke-light-green: #79B7B5;
    --grey-10: rgba(255, 255, 255, 0.12);
    --light-green: #127E64;
    --maroon: #831C23;
    --dark-blue: #0F1B2D;
    --chalke-off-white: #FFFAF6;
    --white-80: rgba(255, 255, 255, 0.87);
    --white-60: rgba(255, 255, 255, 0.60);
    --white-30: rgba(255, 255, 255, 0.30);
    --grey-90: rgba(255, 255, 255, 0.09);
}

:root.lightTheme {
    --layout-bg: #fff;
    --layout-bg-revert: #000;
    --layout-text: #003A30;
    --button-bg: #003A30;
    --button-text: #fff;
    --link-hover: #000;
    --chart-opacity: 1;
    --header-border: #F0F0F0;
    --footer-border: #F0F0F0;
    --input-text: #161F64;
    --input-bg: transparent;
    --input-border: #003A30;
    --checkbox-border: #003A30;
    --back-color: #1de3ff;
    --input-error: #f00;
    --input-focus: #000;
    --section-bg: #FFFAF6;
    --section-border: #f6f6f6;
    --faq-bg: #f0f0f0;
    --layout-bg-opacity: #0000000d;
    --on-surface-disabled: #00000061;
    --on-surface-medium-emphasis: #00000099;
    --on-primary-medium-emphasis: #ffffffbd;
    --secondary-color: #161F64;
    --placeholder-bg: #4a4a4a;
    --link-border: #00000017;
    --opacity-bg: #0000004d;
    --dropdown-shadow: #ffffff33;
    --multiselect-hover: #f0f0f0;
    --radio-select: #000161;
}

* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    box-sizing: border-box;
}

html,
body {
    background: #121212;
    background: var(--section-bg);
    transition: background-color 0.4s ease-in-out;
    color: #fff;
    color: var(--layout-text);
}

html.overflow-hidden body {
    overflow: hidden;
}

body,
input,
textarea,
select,
.Toastify .Toastify__toast-theme--light {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

input,
textarea,
select {
    color: #161F64;
    color: var(--input-text);
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

img {
    max-width: 100%;
    border-style: none;
    width: auto;
    height: auto;
}

p {
    line-height: 1.65;
}

label {
    cursor: pointer;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}

a:active {
    background-color: transparent;
}

select,
map,
area,
a,
button {
    border: 0px;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

input[type=text],
input[type=password],
textarea {
    text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    appearance: none;
}

input::-ms-clear {
    display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
    display: none;
}

input,
textarea,
select {
    border: 0;
    outline: none;
    line-height: normal;
    -webkit-appearance: none;
    border-radius: 0;
}

textarea {
    resize: none;
}

body>iframe {
    display: none !important;
}

.darkTheme img[data-theme="light"],
.lightTheme img[data-theme="dark"] {
    display: none;
}

body .button.play_white {
    color: #000;
    color: var(--layout-bg);
    background-color: #fff;
    background-color: var(--layout-bg-revert);
    border-color: #fff;
    border-color: var(--layout-bg-revert);
    border-radius: 4px;
}

body .button.more_info_trans {
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    color: var(--layout-bg-revert);
    border-color: #fff;
    border-color: var(--layout-bg-revert);
    border-radius: 4px;
}

body .save_btn {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-size: 16px;
    border: 1px solid #ffffff61;
    border: 1px solid var(--on-surface-disabled);
    font-weight: 700;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ffffff61;
    background-color: var(--on-surface-disabled);
    color: #fff;
    color: var(--layout-bg-revert);
}

body .save_btn:hover {
    border-color: #EDF959;
    border-color: var(--button-bg);
}

body .button.play_white:hover {
    border-color: #fff;
    border-color: var(--layout-bg-revert);
    color: #fff;
    color: var(--layout-bg-revert);
}

body .button.more_info_trans:hover {
    color: #fff;
    color: var(--layout-bg-revert);
}

.section {
    position: relative;
    padding: 30px;
}

.content {
    max-width: 1400px;
    margin: auto;
    position: relative;
}

.p_disable {
    opacity: 0.5;
    pointer-events: none;
}

.loader.last-section {
    padding-bottom: 80px;
}

.first-section {
    padding-top: 80px !important;
}

/* Toastify css begin*/
.Toastify .Toastify__toast-theme--light {
    background-color: #EDF959;
    background-color: var(--button-bg);
    color: #000;
    color: var(--button-text);
    font-weight: 700;
}

.Toastify__toast-container {
    --toastify-z-index: 100000;
}

/* Toastify css end*/

@media (max-width: 1200px) {
    .section {
        padding: 26px;
    }
}

@media (max-width: 800px) {
    .section {
        padding: 16px;
    }
}
.style_content__12v-z {
  min-height: 100vh;
}

.darkTheme .style_heightBar__2_LrB {
  display: none;
}

.darkTheme.showHeight .style_heightBar__2_LrB {
  display: block;
}

.style_header__1a32I {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0px;
  transition: background-color 0.8s;
  border-bottom: 1px solid var(--header-border);
  z-index: 99;
}

.style_campaign__C-m7r.style_header__1a32I,
.style_register__2XP3w.style_header__1a32I {
  background-color: var(--dark-green);
}

.articlePage .style_header__1a32I,
.style_header__1a32I.style_fixedHeader__1Rmzo,
.fixedHeader {
  background-color: var(--layout-bg);
}

.style_headerLoader__3vE26 {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 64px;
  gap: 64px;
}

.style_headerLoader__3vE26 .style_logo__2NQ3p {
  position: relative;
  width: 120px;
  height: 50px;
}

.style_headerLoader__3vE26 .style_menus__2w-7O {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  padding-left: 220px;
}

.style_headerLoader__3vE26 .style_menus__2w-7O>div {
  width: 160px;
  margin: 0;
}

.style_headerLoader__3vE26 .style_button__28zjz,
.style_headerLoader__3vE26 .style_profile__31pPw {
  position: relative;
  right: 0;
  top: -13px;
}

.style_headerLoader__3vE26 .style_profile__31pPw {
  top: -6px;
}

.style_headerLoader__3vE26 .style_profile__31pPw>div {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.style_headerLoader__3vE26 .style_button__28zjz {
  width: 220px;
  height: 50px;
}

.style_headerLoader__3vE26 .style_button__28zjz>div {
  border-radius: 50px;
}

.style_register__2XP3w.style_header__1a32I .style_hamburgerMenu__2B0zV {
  display: none;
}

.style_floatingHeader__3hjFO {
  border-bottom-width: 0px;
  background: linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
  z-index: 9;
  position: absolute;
}

.style_floatingHeader__3hjFO+div {
  display: none;
}

.black-header .style_header__1a32I {
  background-color: var(--layout-bg);
}

.style_headerCenter__2RvCk {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style_headerMenu__2jvDs .style_linkItem__48YJg {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  padding: 30px 0;
  color: var(--nude);
}

.style_headerMenu__2jvDs .style_linkItem__48YJg .style_chevronArrow__2_XRN {
  display: none;
}

.style_headerMenu__2jvDs .style_link__2faFm {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 4px;
  margin-left: 15px;
  border-bottom: 2px solid transparent;
}

.style_headerMenu__2jvDs .style_link__2faFm:first-child {
  margin-left: 0;
}

.style_headerMenu__2jvDs .style_link__2faFm:hover {
  border-bottom-color: var(--layout-text);
}

.style_headerMenu__2jvDs .style_link__2faFm.style_active__3-V1H {
  font-weight: 700;
  border-bottom-color: var(--layout-text);
}

.style_submenu__3EIT1 {
  position: absolute;
  left: -15px;
  top: 75px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  min-width: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.74);
}

.style_header__1a32I .style_linkItem__48YJg:hover .style_submenu__3EIT1 {
  opacity: 1;
  visibility: visible;
}

.style_submenu__3EIT1 .style_submenuItem__2IgNH {
  padding-top: 10px;
  display: block;
  font-weight: 700;
  color: var(--nude);
}

.style_submenu__3EIT1 .style_submenuItem__2IgNH:first-child {
  padding-top: 0;
}

.style_submenu__3EIT1 .style_submenuItem__2IgNH span {
  border-bottom: 2px solid transparent;
  transition: border 0.4s;
  padding-bottom: 5px;
  display: inline-block;
}

.style_submenu__3EIT1 .style_submenuItem__2IgNH:hover span,
.style_submenu__3EIT1 .style_submenuItem__2IgNH.style_active__3-V1H span {
  border-bottom-color: var(--layout-text);
}

.style_logo__2NQ3p {
  display: inline-block;
  vertical-align: middle;
  min-width: 200px;
}

.style_logo__2NQ3p .style_tabletLogo__3FYuu,
.style_logo__2NQ3p .style_mobileLogo__1QCuA {
  display: none;
}

.style_logo__2NQ3p img {
  height: auto;
  width: 240px;
}

.style_logo__2NQ3p .style_mobileLogo__1QCuA img {
  width: 150px;
}


/* Footer style starts */

.style_footer__kGuvF {
  margin-top: 4.32vw;
  padding: 56px 0px;
  color: var(--nude);
  position: relative;
}

.style_register__2XP3w.style_footer__kGuvF {
  background-color: var(--dark-green);
}

.style_footer__kGuvF>div:first-child {
  max-width: 571px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.style_footer__kGuvF .style_bg_image__29jcY {
  background-size: contain;
  background-position: bottom;
}


.style_footer__kGuvF .style_inner_wrap__1LnhD {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.style_footer__kGuvF .style_inner_wrap__1LnhD::after {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 100%;
  left: 61%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: var(--sushi-green);
}

.style_campaignPage__2cCud.style_footer__kGuvF {
  background-color: var(--grey);
  border-top: 1px solid var(--layout-text);
}

.style_footerCenter__3vM-r {
  max-width: 1100px;
  margin: auto;
}

.style_footer__kGuvF .style_logo__2NQ3p img {
  height: 40px;
}

.style_footerLeft__2RwmT {
  padding-right: 40px;
  width: 61%;
  display: flex;
  position: relative;
}

.style_social_link_wrap__2OYLV {
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  left: 39px;
}

.style_footerRight__1WgfW {
  padding-left: 40px;
  width: 39%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.style_footerRight__1WgfW .style_address__1_z80,
.style_footerRight__1WgfW .style_greet_text__3Bix1 {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}

.style_footerRight__1WgfW .style_address__1_z80 {
  padding-top: 32px;
}

.style_footer_logo__3m5-Q {
  position: absolute;
  top: 0;
  right: 0;
}

.style_brand_image_wrap__1mUVK {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
}

.style_brand_image__IencJ:hover {
  opacity: 0.7;
}

.style_brand_image__IencJ+.style_brand_image__IencJ {
  padding-left: 24px;
}

.style_brand_image__IencJ img {
  max-height: 37px;
}

.style_footer_logo__3m5-Q img {
  height: 110px;
}

.style_brand_image__IencJ .style_sizer___gCZM {
  padding-top: 10%;
}


.style_supportImage__15DjO {
  width: 200px;
}

.style_footerLeft__2RwmT .style_footerCol__21LGr {
  padding-right: 39px;
}

.style_footerLeft__2RwmT .style_footerCol__21LGr:last-child {
  padding-right: 0;
}

.style_footerCol__21LGr.style_flex_wrap__28BAl {
  position: relative;
}

.style_footerTitle__-zB3B {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  text-transform: uppercase;
  line-height: 2;
}

.style_footerLinkItem__3dWQu {
  font-size: 16px;
  line-height: 2;
  font-weight: 500;
  text-transform: capitalize;
}

.style_relative__32tIJ {
  position: relative;
}

.style_footerLinkItem__3dWQu:hover {
  color: var(--link-hover);
}

.style_footerText__3W72Y {
  position: relative;
}

.style_footerText__3W72Y img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -18px;
  opacity: var(--chart-opacity);
  transition: opacity 0.4s;
}

.style_footerText__3W72Y p {
  font-size: 15px;
  padding: 17px 0 18px 0;
  max-width: 322px;
  margin: 23px 0 60px;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

.style_socials__29DJN {
  margin: 0 -15px;
}

.style_campaignPage__2cCud .style_socials__29DJN {
  margin: 0;
  display: flex;
  align-items: center;
}

.style_campaignPage__2cCud .style_socialItem__TWmR1 {
  margin: 0;
}

.style_campaignPage__2cCud .style_socialItem__TWmR1+.style_socialItem__TWmR1 {
  padding-left: 24px;
}

.style_socialItem__TWmR1 {
  margin: 10px 15px 0;
  display: inline-block;
  font-size: 24px;
}

.style_socialItem__TWmR1:first-child {
  margin-left: 0;
}

.style_companyRegistration__1s1td {
  margin-top: 60px;
  font-size: 10px;
}

.style_profileSection__29kVt>* {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.style_profileSection__29kVt .style_icon__FWsPu {
  position: relative;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.style_profileSection__29kVt .style_search__3vYp_ svg {
  width: 32px;
  height: 32px;
}

.style_profileSection__29kVt .style_search__3vYp_:hover {
  color: var(--link-hover);
}

.style_profileSection__29kVt .style_letter__2tVVf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--button-bg);
  color: var(--button-text);
  font-weight: 600;
  text-transform: uppercase;
}

.profile .style_profileSection__29kVt .style_icon__FWsPu {
  border: 2px solid var(--layout-text);
}

.style_content__12v-z .section:last-child {
  padding-bottom: 60px;
}

.style_hamburgerMenu__2B0zV {
  display: none;
}

.style_authorize__3KkEU {
  position: relative;
  border-top: 1px solid var(--footer-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_authorize__3KkEU .style_container__3QlRM {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  background-color: var(--button-bg);
  padding: 50px 90px;
  border-radius: 18px;
  color: #000;
  min-width: 520px;
  z-index: 2;
  text-align: center;
}

.style_authorize__3KkEU .style_spinner___AlBx {
  position: relative;
  height: 20px;
}

.style_authContainer__3xuzM {
  color: #fff;
  background-color: #000;
  text-align: center;
  margin: auto;
}

.style_authContainer__3xuzM .style_authContent__Ir_-Q {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.style_authContent__Ir_-Q .style_spinner___AlBx {
  position: relative;
  height: 50px;
}

/* Mobile Hambuger menu begin */
.style_mobileMenu__1My1t {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: auto;
  z-index: 9998;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
}

.style_mobileMenu__1My1t.style_show__1vWw3 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.style_mobileMenu__1My1t .style_container__3QlRM {
  position: absolute;
  color: #fff;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
}

.style_mobileMenu__1My1t .style_closeBtn__bHUDK {
  position: fixed;
  padding: 30px 26px;
}

.style_mobileMenu__1My1t .style_closeBtn__bHUDK svg {
  width: 18px;
  height: 18px;
}

.style_mobileMenu__1My1t .style_items__1G0v2 {
  position: relative;
  display: grid;
  grid-gap: 32px;
  gap: 32px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.2;
}

.style_mobileMenu__1My1t .style_items__1G0v2>a {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--link-border);
}

.style_mobileMenu__1My1t .style_linkItem__48YJg {
  position: relative;
  padding-bottom: 28px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--footer-border);
}

.style_mobileMenu__1My1t .style_linkItem__48YJg .style_link__2faFm {
  display: block;
}

.style_mobileMenu__1My1t .style_linkItem__48YJg .style_chevronArrow__2_XRN {
  position: absolute;
  top: -5px;
  right: -10px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  padding: 10px;
}

.style_mobileMenu__1My1t .style_linkItem__48YJg .style_chevronArrow__2_XRN>div {
  width: 16px;
  height: 16px;
}

.style_mobileMenu__1My1t .style_submenu__3EIT1 .style_submenuItem__2IgNH.style_active__3-V1H span {
  border-bottom-color: transparent;
}

.style_mobileMenu__1My1t .style_submenu__3EIT1 {
  position: static;
  text-transform: none;
  max-height: 0;
  transition: opacity 0.4s 0s, visibility 0.4s 0s, max-height 0.4s 0s;
  padding-left: 0;
  padding-right: 0;
}

.style_mobileMenu__1My1t .style_showSubMenu__3CbMM .style_submenu__3EIT1 {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s 0.4s, visibility 0.4s 0.4s max-height 0.4s 0.5s;
}

.style_mobileMenu__1My1t .style_linkItem__48YJg.style_hasSubmenu__vXQqQ {
  padding-bottom: 0;
}

.style_mobileMenu__1My1t .style_showSubMenu__3CbMM .style_chevronArrow__2_XRN {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

/* Mobile Hambuger menu end */

/* Welcome Popup Begin */
.style_welcomePopup__3Y24Q {
  position: relative;
  padding: 0 16px;
}

.style_welcomePopup__3Y24Q .style_inner_wrap__1LnhD {
  position: relative;
  min-height: 100vh;
}

.style_welcomePopup__3Y24Q .style_text__33Zw8 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: var(--layout-bg);
  border-radius: 4px;
  color: var(--layout-text);
  padding: 80px;
  max-width: 680px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.20);
}

.style_welcomePopup__3Y24Q h4 {
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.05;
  font-weight: 700;
  color: var(--nude);
}

.style_welcomePopup__3Y24Q p {
  margin-top: 16px;
  font-size: 16px;
  color: var(--chalke-off-white)
}

.style_welcomePopup__3Y24Q .style_button_wrap__3UBB5 {
  padding-top: 32px;
}

.style_welcomePopup__3Y24Q .style_button_wrap__3UBB5 button {
  margin-right: 24px;
}

/* Welcome Popup Css */

/* Footer Podium logo begin */
.style_podiumLogo__2bNHF {
  position: absolute;
  right: 60px;
  bottom: 0;
}

.style_podiumLogo__2bNHF .style_logo__2NQ3p {
  text-align: end;
}

.style_footerPodiumLogo__3KRkF .style_logo__2NQ3p img,
.style_podiumLogo__2bNHF .style_logo__2NQ3p img {
  height: 52px !important;
}

.style_podiumHeaderLogo__h2Gwg img {
  height: 45px;
}

/* Footer Podium logo end */

.style_blackBar__2mCeR.style_header__1a32I {
  background-color: #000;
}

.style_signInButton__2YaDY {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

@media(min-width: 1920px) {
  .style_footer__kGuvF {
    margin-top: 83px;
  }
}

@media(max-width: 1300px) {
  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-right: 35px;
  }
}

@media (max-width: 1200px) {
  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-right: 30px;
  }

  .style_footer__kGuvF .style_inner_wrap__1LnhD::after {
    left: 56%;
  }

  .style_footerLeft__2RwmT {
    padding-bottom: 52px;
    width: 58%;
    justify-content: space-between;
  }

  .style_footerRight__1WgfW {
    width: 45%;
  }

  .style_footerCol__21LGr.style_flex_wrap__28BAl {
    position: static;
  }

  .style_social_link_wrap__2OYLV {
    left: 0;
  }
}


@media (max-width: 1024px) {
  .style_header__1a32I {
    padding: 16px 0;
  }

  .style_logo__2NQ3p .style_desktopLogo__1ahcl {
    display: none;
  }

  .style_logo__2NQ3p .style_tabletLogo__3FYuu {
    display: inline;
  }

  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-right: 28px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    max-width: 716px;
  }

  .style_header__1a32I .style_logo__2NQ3p {
    min-width: 0;
  }

  .style_footerText__3W72Y img {
    left: -16px;
  }

  .style_podiumLogo__2bNHF {
    right: 40px;
  }

  .style_podiumHeaderLogo__h2Gwg img {
    height: 40px;
  }
}

@media(max-width: 1023px) {
  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-left: 6vw;
    padding-right: 6vw;
  }
}

@media(max-height: 900px) {
  .style_logo__2NQ3p img {
    height: auto;
  }
}

@media(max-width: 900px) {
  .style_footer__kGuvF {
    padding-top: 50px;
  }


  .style_footer_logo__3m5-Q {
    position: static;
  }

  .style_footer__kGuvF .style_inner_wrap__1LnhD::after {
    display: none;
  }

  .style_footer__kGuvF .style_inner_wrap__1LnhD {
    display: block;
  }

  .style_footerLeft__2RwmT {
    width: 100%;
    flex-wrap: wrap;
    justify-content: initial;
    padding-right: 0;
  }

  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-top: 32px;
    padding-left: 0;
    padding-right: 24px;
  }

  .style_footerRight__1WgfW {
    text-align: left;
    padding-left: 0;
    padding-top: 32px;
    width: 100%;
  }

  .style_brand_image_wrap__1mUVK {
    justify-content: flex-start;
    max-width: 260px;
  }

  .style_brand_image__IencJ+.style_brand_image__IencJ {
    padding-left: 20px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 850px) {
  .style_footer__kGuvF {
    padding-top: 44px;
  }

  .style_companyRegistration__1s1td,
  .style_socials__29DJN {
    margin-top: 40px;
  }

  .style_footerText__3W72Y p {
    margin: 30px 0 0;
  }
}

@media(max-width: 820px) {
  .style_header__1a32I {
    padding: 12px 0;
  }

  .style_footer__kGuvF {
    padding-top: 38px;
  }


  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-right: 20px;
  }

  .style_brand_image__IencJ+.style_brand_image__IencJ {
    padding-left: 16px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .style_welcomePopup__3Y24Q h4 {
    font-size: 37px;
  }
}

@media (max-width: 800px) {
  .style_footer__kGuvF {
    padding-top: 32px;
  }

  .style_logo__2NQ3p .style_tabletLogo__3FYuu {
    display: none;
  }

  .style_logo__2NQ3p .style_mobileLogo__1QCuA {
    display: inline;
  }

  .style_footerText__3W72Y p {
    max-width: 400px;
  }

  .style_footerRight__1WgfW {
    overflow: visible;
    width: 100%;
    max-width: 500px;
  }

  .style_footer__kGuvF .style_logo__2NQ3p img {
    height: 40px;
  }

  .style_headerMenu__2jvDs {
    display: none;
    cursor: pointer;
  }

  .style_header__1a32I .style_clearClass__17e5Y {
    display: none;
  }

  .style_headerCenter__2RvCk {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .style_signInButton__2YaDY a {
    padding: 8px;
    font-size: 14px;
  }

  .style_hamburgerMenu__2B0zV {
    display: inline-block;
    width: 16px;
    height: 14px;
    position: relative;
    margin: 3px 2px;
    cursor: pointer;
    cursor: pointer;
  }

  .style_hamburgerMenu__2B0zV div {
    opacity: 1;
    transition: opacity 0.4s;
  }

  .style_hamburgerMenu__2B0zV div,
  .style_hamburgerMenu__2B0zV::before,
  .style_hamburgerMenu__2B0zV::after {
    background-color: var(--layout-bg-revert);
    width: 100%;
    height: 2px;
  }

  .style_hamburgerMenu__2B0zV::before,
  .style_hamburgerMenu__2B0zV::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    transition-duration: 0.3s, 0.3s, 0.3s;
    /* transition-delay: .3s, 0s, 0s; */
  }

  .style_hamburgerMenu__2B0zV::after {
    top: auto;
    bottom: 0;
  }

  .style_hamburgerMenu__2B0zV.style_toggled__D0bHN:before {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }

  .style_hamburgerMenu__2B0zV.style_toggled__D0bHN:after {
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
  }

  .style_hamburgerMenu__2B0zV.style_toggled__D0bHN div {
    opacity: 0;
  }

  .style_hamburgerMenu__2B0zV.style_toggled__D0bHN::before,
  .style_hamburgerMenu__2B0zV.style_toggled__D0bHN::after {
    transition-delay: 0s, 0.3s;
    top: 50%;
    left: 0;
    bottom: auto;
  }

  .style_profileSection__29kVt>* {
    margin: 0;
  }

  .style_profileSection__29kVt .style_icon__FWsPu {
    margin-left: 8px;
  }

  .style_profileSection__29kVt .style_search__3vYp_ svg {
    width: 16px;
    height: 16px;
  }

  .style_footerText__3W72Y p {
    padding-right: 120px;
    margin: 30px 0 40px;
  }

  .style_supportImage__15DjO {
    margin: 30px 0 40px;
  }

  .style_podiumLogo__2bNHF {
    position: relative;
    padding: 40px 40px 0;
  }

  .style_podiumLogo__2bNHF .style_logo__2NQ3p {
    text-align: start;
  }

  .style_podiumHeaderLogo__h2Gwg img {
    height: 35px;
  }
}

@media(max-width: 740px) {
  .style_footer__kGuvF {
    padding-top: 28px;
  }


  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    padding-right: 16px;
  }

  .style_brand_image__IencJ+.style_brand_image__IencJ {
    padding-left: 12px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .style_welcomePopup__3Y24Q h4 {
    font-size: 34px;
  }
}

@media(max-width: 650px) {
  .style_welcomePopup__3Y24Q .style_button_wrap__3UBB5 button {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .style_welcomePopup__3Y24Q .style_button_wrap__3UBB5 button+button {
    margin-top: 24px;
  }
}

@media (max-width: 550px) {
  .style_footer__kGuvF {
    padding-top: 24px;
  }

  .style_logo__2NQ3p {
    min-width: 100px;
  }

  .style_footerText__3W72Y p {
    padding: 10px 120px 10px 0;
  }

  .style_supportImage__15DjO {
    margin: 50px 0 40px;
  }

  .style_podiumHeaderLogo__h2Gwg img {
    height: 30px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .style_welcomePopup__3Y24Q h4 {
    font-size: 33px;
  }
}

@media (max-width: 450px) {
  .style_header__1a32I {
    padding: 8px 0;
  }

  .style_footer__kGuvF {
    margin-top: 0;
  }

  .style_footerRight__1WgfW {
    flex-wrap: wrap;
  }

  .style_footerLeft__2RwmT {
    display: block;
  }

  .style_footerLeft__2RwmT .style_footerCol__21LGr {
    white-space: wrap;
    display: inline-block;
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .style_footerLeft__2RwmT .style_footerCol__21LGr:nth-child(odd) {
    padding-left: 0;
  }

  .style_mobileMenu__1My1t .style_container__3QlRM {
    padding: 0 16px;
  }

  .style_footer__kGuvF .style_logo__2NQ3p img {
    height: 36px;
  }

  .style_welcomePopup__3Y24Q .style_text__33Zw8 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 380px) {
  .style_footerText__3W72Y p {
    padding-right: 40px;
  }
}
.style_modal__3v0NW,
.style_dialogBoxOverlayWrap__3O8qp,
.style_dialogBoxOverlay__1dJ2a {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.style_modal__3v0NW {
    position: fixed;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in;
}

.style_modal__3v0NW.style_show__31XbE {
    opacity: 1;
    visibility: visible;
}

.style_modal__3v0NW.style_hide__iWpNz {
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s 0.4s ease-out;
}

.style_dialogBoxOverlayWrap__3O8qp {
    background: #000000a6;
    color: #0F2846;
    position: absolute;
}

.style_dialogBoxOverlay__1dJ2a {
    position: absolute;
    overflow: auto;
    z-index: 1;
}

.style_dialogOpen__2Tbji {
    opacity: 1;
}

.style_dialogRemove__Yoele {
    opacity: 0;
    transition: opacity 0.6s ease-out;
}

.style_dialogClose__1GTXb {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 15px 20px;
    background-color: transparent;
    z-index: 2;
}

.style_closeIcon__2GVJy {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
}

.style_closeText__3_HAD {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    position: relative;
    top: 2px;
}

.style_dialogClose__1GTXb:hover {
    color: #000
}

.style_dialogContent__xvujj {
    position: relative;
}

.style_dialogItem__1buPv {
    position: relative;
    padding: 16px;
    text-align: left;
    color: var(--layout-text);
    background-color: var(--layout-bg);
    cursor: pointer;
    border: 1px solid #0000;
    border-radius: 6px;
}

.style_dialogItem__1buPv .style_link__2VoyW{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.style_dialogItem__1buPv:hover {
    border-color: var(--button-bg);
}

.style_dialogItem__1buPv.style_active__2HHbI {
    background-color: var(--button-bg);
    color: #000;
}

.style_dialogItem__1buPv.style_active__2HHbI .style_radio__2Tzmj {
    background-color: var(--layout-bg);
    opacity: 1;
}

.style_dialogItem__1buPv h4 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    padding-bottom: 4px;
}

.style_dialogItem__1buPv .style_radio__2Tzmj {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-color: var(--button-bg);
    opacity: .4;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.style_dialogItem__1buPv.style_padd__38D95 h4 {
    padding-left: 35px;
}

@media (max-width: 550px) {
    .style_closeText__3_HAD {
        display: none;
    }

    .style_dialogClose__1GTXb {
        right: 0;
        background-color: #fff;
        top: 0;
    }

    .style_col__3eTMM {
        display: block;
        width: auto;
    }
}
.style_formGroup__1onlG {
    position: relative;
}

input[type="text"].style_textbox__npUdS,
.style_textbox__npUdS {
    font-size: 16px;
    padding: 15px;
    width: 100%;
    background: transparent;
    text-align: left;
    background-color: var(--input-bg);
}

.PhoneInput {
    font-size: 16px;
    padding: 15px;
    width: 100%;
    background: transparent;
    text-align: left;
}

.style_inputDisplay__1TuWv .style_textbox__npUdS {
    line-height: 1.65;
    padding: 15px 0 10px;
}

.style_formField__1Fa0n,
.style_otpInput__L_aPe {
    border: 1px solid var(--input-border);
    position: relative;
    z-index: 2;
    margin-top: 14px;
    border-radius: 5px;
    transition: border-color 0.4s;
}

.style_inputError__1-5KL {
    border-color: var(--input-error);
}

.style_inputFocus__1Ac0t {
    border-color: var(--input-focus);
}

.style_noLabel__1mdYS .style_textbox__npUdS {
    padding: 8px 15px;
}

textarea.style_textbox__npUdS {
    height: 200px;
}

.style_selectBox__2sIwu {
    transition: opacity 0.4s;
}

.style_selectBox__2sIwu select {
    opacity: 0;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.style_selectBox__2sIwu .style_fa__1SFcv,
.style_selectBox__2sIwu .style_spinner__3gtbd {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 18px;
}

.style_selectBox__2sIwu .style_fa__1SFcv {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 20px;
}

.style_selectDisabled__BImHr {
    opacity: 0.3;
}

.style_inputFetching__1cyN0 .style_textbox__npUdS {
    padding: 15px;
}

.style_inputFetchingLabel__5K5V6+div {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 15px;
    margin: 0;
    left: auto;
}

.style_checkbox__3iEqK {
    cursor: pointer;
}

.style_checkbox__3iEqK:hover {
    color: var(--link-hover);
}

.style_checkbox__3iEqK>* {
    display: inline-block;
    vertical-align: middle;
}

.style_checkboxSquare__3mDLL {
    display: inline-block;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--checkbox-border);
    position: relative;
    border-radius: 5px;
    transition: background-color 0.4s;
}

.style_checkboxText__1w4SQ>* {
    vertical-align: middle;
}

.style_checkboxText__1w4SQ {
    line-height: 1.5;
}

.style_controlLabel__3XZeB {
    text-align: left;
}

.style_inputDisplay__1TuWv .style_controlLabel__3XZeB {
    font-weight: 700;
}

.style_checkboxInput__2DNIE:checked+.style_checkboxSquare__3mDLL {
    background-color: var(--checkbox-border);
}

.style_checkboxInput__2DNIE+.style_checkboxSquare__3mDLL::before {
    content: '';
    display: block;
    z-index: 4;
    width: 10px;
    height: 4px;
    border-left: 2px solid var(--button-text);
    border-bottom: 2px solid var(--button-text);
    -webkit-transform: translate(-50%, -68%) rotate(-45deg);
            transform: translate(-50%, -68%) rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: opacity 0.4s;
    opacity: 0;
}

.style_checkboxInput__2DNIE:checked+.style_checkboxSquare__3mDLL::before {
    opacity: 1;
}

.style_checkboxText__1w4SQ a {
    position: relative;
}

.style_checkboxText__1w4SQ a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: var(--link-hover);
}

.style_checkboxText__1w4SQ a:hover::after {
    -webkit-animation: style_border-animation__3WPvP .8s;
            animation: style_border-animation__3WPvP .8s;
}

.style_button__DJzdc {
    font-size: 16px;
    display: inline-block;
    text-align: center;
    position: relative;
    border: 1px solid;
    padding: 17px 24px;
    border-radius: 8px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: var(--nude);
    color: var(--dark-green);
}

.style_button__DJzdc.style_buttonFull__3fu4l {
    width: 100%;
    padding: 15px 30px;
    font-size: 1.15em;
    font-weight: 700;
}

.style_button__DJzdc:not(.style_buttonDisabled__2hKkg):hover,
.style_buttonInvert__7pMhk {
    background-color: transparent;
    color: var(--nude);
}

.style_button__DJzdc.style_outlineBtn__ZStkL:hover,
.style_buttonInvert__7pMhk:not(.style_buttonDisabled__2hKkg):hover {
    background-color: var(--button-bg);
    color: var(--button-text);
}

.style_button__DJzdc.style_outlineBtn__ZStkL {
    background-color: #0000;
    color: var(--button-bg);
}

.style_buttonDisabled__2hKkg {
    opacity: 0.5;
}

.style_buttonDisabled__2hKkg>span {
    vertical-align: middle;
}

.style_buttonQuestion__2Xa1E {
    margin-left: 5px;
}

.style_button__DJzdc .style_icon_label__3DJRl,
.style_button__DJzdc .style_icon_svg__YAbPL {
    display: inline-block;
    vertical-align: middle;
}

.style_button__DJzdc .style_icon_left__2YOkV {
    padding-right: 10px;
}

.style_button__DJzdc .style_icon_right__1g2i0 {
    padding-left: 10px;
}

.style_required__2nfMD {
    color: inherit;
}

.style_helpBlock__hqfVm {
    color: var(--input-error);
    position: absolute;
    right: 0;
    top: 100%;
    font-size: 13px;
    z-index: 4;
    white-space: nowrap;
    padding: 10px 15px 10px 10px;
    line-height: 1;
}

.style_tags__2ryeU {
    margin: 0 -3px;
    max-height: 95px;
    overflow: auto;
}

.style_tag__RRUK8 {
    display: inline-block;
    padding: 4px 10px;
    margin: 3px;
    border-radius: 5px;
    background-color: var(--tag-bg);
    color: var(--input-bg);
    font-size: 13px;
}

.style_tag__RRUK8>* {
    vertical-align: middle;
}

.style_tag__RRUK8>button {
    margin-left: 10px;
}

.style_asyncInputTag__13mrb {
    z-index: 10;
}

input[type="text"].style_autcompleteInput__2yKJ9 {
    width: 100px;
    padding: 5px 0 0 5px;
    border: 0;
}

.style_autocompleteLoading__1ICBF {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    width: 30px;
}

.style_autocompleteResult__3Ys4b {
    position: absolute;
    left: -1px;
    margin-top: 3px;
    top: 100%;
    right: -1px;
    max-height: 339px;
    background-color: var(--default-bg);
    border: 1px solid var(--input-border);
    overflow: auto;
    border-radius: 5px;
}

.style_autocompleteResultItem__1nelU {
    border-top: 1px solid var(--autocomplete-border);
    display: block;
    padding: 12px 15px;
    font-size: 12px;
    width: 100%;
    text-align: left;
}

.style_autocompleteResultItem__1nelU:hover,
.style_autocompleteResultActive__2mdQb {
    background-color: var(--autocomplete-hover);
    color: var(--text-hover);
}

.style_autocompleteResultItem__1nelU:first-child {
    border-top: 0;
}

.style_recaptcha__2tHhI {
    text-align: center;
}

.style_recaptcha__2tHhI>div {
    display: inline-block;
}

.style_otpInputContainer__1KwCT {
    flex-direction: row;
    margin: 0 -10px;
}

.style_otpInputContainer__1KwCT>* {
    flex: 1 1;
    padding: 0 10px;
}

.style_otpInput__L_aPe {
    width: 100% !important;
    padding: 15px 0 !important;
    font-size: 24px;
    margin-top: 15px;
}

.style_otpError__1y3K- {
    color: var(--input-error);
    text-align: right;
    padding: 5px 0 5px 0;
}

button.style_outlineBtn__ZStkL {
    border-color: #fff;
    color: #fff;
    background-color: #0000;
}

button.style_button__DJzdc.style_outlineBtn__ZStkL:hover {
    border-color: var(--button-bg);
    background-color: var(--button-bg);
    color: var(--button-text);
}

.style_filterBox__XiZx5 {
    position: relative;
    border: 1px solid var(--layout-bg-revert);
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
}

.style_filterBox__XiZx5.style_toggled__3PtYv {
    background-color: var(--on-surface-disabled);
}

.style_filterBox__XiZx5 .style_label__fZ_ki {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 158px;
}

.style_filterBox__XiZx5 .style_options__x7938 {
    position: absolute;
    z-index: 99;
    background-color: #000;
    width: 100%;
    left: 0;
    padding: 8px 16px;
    list-style: none;
    margin-top: 10px;
    max-height: 220px;
    overflow: auto;
}

.style_filterBox__XiZx5 .style_options__x7938::-webkit-scrollbar {
    width: 4px;
}

.style_filterBox__XiZx5 .style_options__x7938::-webkit-scrollbar-track {
    background-color: #ffffff30;
}

.style_filterBox__XiZx5 .style_options__x7938::-webkit-scrollbar-thumb {
    background-color: rgb(232, 229, 229);
}

.style_filterBox__XiZx5 .style_label__fZ_ki {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}

.style_filterBox__XiZx5 .style_label__fZ_ki .style_rotate__sJ9Tb {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
}

.style_filterBox__XiZx5 .style_label__fZ_ki label {
    font-size: 16px;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.style_filterBox__XiZx5 .style_options__x7938 .style_optionItem__JNWA0 {
    display: inline-block;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    color: var(--layout-text);
    border-bottom: 1px solid var(--on-surface-disabled);
    padding: 8px 0;
}

.style_filterBox__XiZx5 .style_options__x7938 .style_optionItem__JNWA0:last-child {
    border-color: #0000;
}

/* Profile Picture Uploader Begin */
.style_profilePicture__3_3Cw {
    position: relative;
    width: 130px;
    height: 130px;
    min-width: 130px;
    background-color: var(--nude);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}

.style_profilePicture__3_3Cw.style_customUpload__2CVt6 {
    pointer-events: none;
}

.style_profilePicture__3_3Cw .style_uploadIcon__EW458 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--on-primary-medium-emphasis);
    z-index: 1;
    color: var(--link-hover);
    transition: opacity .4s;
    opacity: 0;
    visibility: hidden;
}

.style_profilePicture__3_3Cw .style_uploadIcon__EW458 input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
}

.style_profilePicture__3_3Cw:hover .style_uploadIcon__EW458 {
    opacity: 1;
    visibility: visible;
}

.style_profilePicture__3_3Cw .style_uploadIcon__EW458 svg {
    width: 60px;
    height: 60px;
}

.style_profilePicture__3_3Cw .style_picture__3YaZq svg {
    width: 80px;
    height: 80px;
}

.style_profilePicture__3_3Cw .style_picture__3YaZq .style_letter__3rpCh {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: var(--button-text);
    font-size: 130px;
}

.style_profileText__3ggBC {
    font-size: 16px;
    line-height: 1.5;
}

/* Profile Picture Uploader Css */

/* Multiple Select css Begin*/

.style_formGroup__1onlG.style_boldLabel__3FoN4 .style_controlLabel__3XZeB {
    font-weight: 700;
}

.style_upperCaseLabel__RUYuV {
    text-transform: uppercase;
    line-height: 1.2;
}

.style_formGroup__1onlG.style_boldLabel__3FoN4 .style_controlLabel__3XZeB .style_required__2nfMD {
    padding-right: 3px;
}

.style_selectMultipleBox__1lbf8 {
    position: relative;
}

.style_selectMultipleBoxList__x4cQn .style_checkbox__3iEqK {
    display: block;
    padding: 8px 15px;
    transition: background-color 0.4s;
}

.style_selectMultipleBoxList__x4cQn .style_checkbox__3iEqK:hover {
    background-color: var(--multiselect-hover);
}

.style_selectMultipleBoxList__x4cQn .style_checkbox__3iEqK:first-child {
    margin-top: 0;
}

.style_selectMultipleBoxList__x4cQn {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: var(--layout-bg);
    margin-top: 0;
    box-shadow: 0 3px 4px #bbb5b524, 0 3px 3px #bbb5b51f, 0 1px 8px var(--dropdown-shadow);
    z-index: 11;
}

.style_selectMultipleBoxList__x4cQn .style_saveBtn__2ED8Y {
    background-color: var(--dark-green);
    color: var(--nude);
    border-color: var(--dark-green);
}

.style_selectMultipleBoxList__x4cQn .style_saveBtn__2ED8Y:hover {
    color: var(--dark-green);
}

.style_selectMultipleBoxList__x4cQn .style_cancelBtn__fv2l6 {
    background-color: transparent;
    color: var(--dark-green);
}

.style_selectMultipleBoxList__x4cQn .style_cancelBtn__fv2l6:hover {
    background-color: var(--dark-green);
    color: var(--nude);
}

.style_selectMultipleBoxOptions__3_L_R {
    margin: -4px;
}

.style_selectMultipleBoxOption__1L3WL {
    position: relative;
    cursor: pointer;
    text-align: start;
    background-color: var(--button-bg);
    color: var(--button-text);
    line-height: 1.2;
    display: inline-block;
    padding: 9px 26px 9px 16px;
    border-radius: 20px;
    margin: 4px;
}

.style_selectMultipleBoxOptionFa__3xKWW {
    position: absolute;
    right: 10px;
    top: 10px;
}

.style_selectMultipleBoxPrompt__1l-AF {
    color: #999;
}

.style_selectMultipleBox__1lbf8 .style_fa__1SFcv {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    font-size: 20px;
}

.style_selectMultipleBox__1lbf8 .style_fa__1SFcv.style_rotate__sJ9Tb {
    -webkit-transform: translateY(-50%) (-180deg);
            transform: translateY(-50%) (-180deg);
}

.style_selectMultipleBoxTrigger__1vD73 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.style_selectMultipleBoxListScroller__16L1x {
    position: relative;
    max-height: 260px;
    padding: 8px 0;
    overflow: auto;
}

.style_selectMultipleBoxListScroller__16L1x .style_checkboxSquare__3mDLL {
    position: absolute;
    margin: auto;
}

.style_selectMultipleBoxListScroller__16L1x .style_checkboxText__1w4SQ {
    padding-left: 35px;
}

.style_selectMultipleBoxButtons__3nYpW {
    text-align: right;
    border-top: 1px solid var(--input-border);
    background-color: 1px solid var(--layout-bg);
    padding-top: 15px;
}

.style_selectMultipleBoxButtons__3nYpW .style_button__DJzdc:first-child {
    margin-right: 15px;
}

.style_selectMultipleBoxButtons__3nYpW .style_button__DJzdc {
    padding: 8px 30px;
}

.style_selectMultipleBoxOther__rHuU1 {
    padding-top: 5px;
}

.style_selectMultipleBoxOther__rHuU1,
.style_selectMultipleBoxOther__rHuU1 input {
    background-color: var(--layout-bg);
    color: var(--layout-text);
    border-radius: inherit;
}

/* Multiple Select css End*/

/* save button css begin */
.style_infoButton__2FHIK,
.style_playButton__2fq3j {
    display: block;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1;
    color: var(--layout-bg);
    border-radius: 4px;
    min-width: 98px;
}

.style_infoButton__2FHIK>*,
.style_playButton__2fq3j>* {
    vertical-align: middle;
    display: inline-block;
}

.style_infoText__1XkQm {
    margin-left: 8px;
}

.style_playButton__2fq3j {
    background-color: var(--layout-bg-revert);
    border-radius: 4px;
    border-color: var(--layout-bg-revert);
}

.style_infoButton__2FHIK {
    background-color: var(--opacity-bg);
    color: var(--layout-bg-revert);
    border-color: var(--opacity-bg);
}

.style_playButton__2fq3j:hover,
.style_infoButton__2FHIK:hover {
    color: var(--layout-text) !important;
}

.style_infoButton__2FHIK svg {
    height: 22px;
}

.style_buttonLink__2xL2t {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* save button css end */

/* Radio button css begin */
.style_checkboxGroup__3kd8D {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    padding-top: 12px;
}

.style_checkboxGroup__3kd8D.style_scrollable__3T4PQ {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0;
    padding: 10px;
}

.style_checkboxGroup__3kd8D .style_checkbox__3iEqK {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.style_checkboxGroup__3kd8D.style_scrollable__3T4PQ>.style_checkbox__3iEqK:first-child {
    padding-top: 0;
}

.style_checkboxGroup__3kd8D.style_scrollable__3T4PQ>.style_checkbox__3iEqK:first-child .style_checkboxTitle__1on_m {
    padding-top: 0;
}

.style_checkboxGroup__3kd8D .style_inlineCheck__2xHNF.style_checkbox__3iEqK {
    width: auto;
    padding-right: 15px;
}

.style_checkboxSelectorWrap__2QJqJ {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 45px;
    margin-right: 10px;
    border: 1px solid var(--input-border);
    transition: border .4s;
}

.style_checkSelect__3Rve2 {
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: var(--sushi-green);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s;
}

.style_checkboxSelector__3D0mC {
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: none;
    color: #151c4e;
}

.style_checkboxGroup__3kd8D .style_checkboxLabel__3rLti {
    cursor: pointer;
}

.style_checkboxGroup__3kd8D .style_checkboxLabel__3rLti span {
    display: inline-block;
    vertical-align: middle;
}

.style_checkboxInput__2DNIE:checked+.style_checkboxLabel__3rLti .style_checkboxSelector__3D0mC {
    display: block;
}

.style_checkboxTitle__1on_m {
    font-weight: bold;
    display: block;
    padding: 10px 0;
}

/* 
.checkbox.checked .checkboxSelectorWrap {
    border: 6px solid var(--radio-select);
} */

.style_checkbox__3iEqK.style_checked__3N7iX .style_checkboxSelectorWrap__2QJqJ .style_checkSelect__3Rve2 {
    opacity: 1;
    visibility: visible;
}

/* Radio button css end */

@media (max-width: 1100px) {
    body .style_formGroup__1onlG+.style_othersInput__nd-mP {
        width: 100%;
        padding: 0;
    }

    .style_helpBlock__hqfVm {
        top: auto;
        bottom: -20px;
        -webkit-transform: none;
                transform: none;
        padding: 0;
    }
}

@media(max-height: 900px) {
    .style_button__DJzdc {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 450px) {
    .style_otpInputContainer__1KwCT {
        margin: 0 -5px;
    }

    .style_otpInputContainer__1KwCT>* {
        padding: 0 5px;
    }
}

@-webkit-keyframes style_border-animation__3WPvP {
    0% {
        width: 0
    }

    20% {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes style_border-animation__3WPvP {
    0% {
        width: 0
    }

    20% {
        width: 0
    }

    to {
        width: 100%
    }
}
.style_spinner__1sIw9 {
    position: relative;
    margin: auto;
}

.style_center__3ntcb {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.style_spinnerBlade__1yUNo {
    position: absolute;
    left: 44.5%;
    top: 37%;
    width: 10%;
    height: 25%;
    border-radius: 50%/20%;
    -webkit-animation: style_spinner-fade__Y3POX 1s linear infinite;
            animation: style_spinner-fade__Y3POX 1s linear infinite;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}

.style_spinnerBlade__1yUNo:nth-child(1) {
    -webkit-animation-delay: -1.66667s;
            animation-delay: -1.66667s;
    -webkit-transform: rotate(30deg) translate(0, -150%);
            transform: rotate(30deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(2) {
    -webkit-animation-delay: -1.58333s;
            animation-delay: -1.58333s;
    -webkit-transform: rotate(60deg) translate(0, -150%);
            transform: rotate(60deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(3) {
    -webkit-animation-delay: -1.5s;
            animation-delay: -1.5s;
    -webkit-transform: rotate(90deg) translate(0, -150%);
            transform: rotate(90deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(4) {
    -webkit-animation-delay: -1.41667s;
            animation-delay: -1.41667s;
    -webkit-transform: rotate(120deg) translate(0, -150%);
            transform: rotate(120deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(5) {
    -webkit-animation-delay: -1.33333s;
            animation-delay: -1.33333s;
    -webkit-transform: rotate(150deg) translate(0, -150%);
            transform: rotate(150deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(6) {
    -webkit-animation-delay: -1.25s;
            animation-delay: -1.25s;
    -webkit-transform: rotate(180deg) translate(0, -150%);
            transform: rotate(180deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(7) {
    -webkit-animation-delay: -1.16667s;
            animation-delay: -1.16667s;
    -webkit-transform: rotate(210deg) translate(0, -150%);
            transform: rotate(210deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(8) {
    -webkit-animation-delay: -1.08333s;
            animation-delay: -1.08333s;
    -webkit-transform: rotate(240deg) translate(0, -150%);
            transform: rotate(240deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(9) {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    -webkit-transform: rotate(270deg) translate(0, -150%);
            transform: rotate(270deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(10) {
    -webkit-animation-delay: -0.91667s;
            animation-delay: -0.91667s;
    -webkit-transform: rotate(300deg) translate(0, -150%);
            transform: rotate(300deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(11) {
    -webkit-animation-delay: -0.83333s;
            animation-delay: -0.83333s;
    -webkit-transform: rotate(330deg) translate(0, -150%);
            transform: rotate(330deg) translate(0, -150%);
}

.style_spinnerBlade__1yUNo:nth-child(12) {
    -webkit-animation-delay: -0.75s;
            animation-delay: -0.75s;
    -webkit-transform: rotate(360deg) translate(0, -150%);
            transform: rotate(360deg) translate(0, -150%);
}

@-webkit-keyframes style_spinner-fade__Y3POX {
    0% {
        opacity: 0.85;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 0.25;
    }
}

@keyframes style_spinner-fade__Y3POX {
    0% {
        opacity: 0.85;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 0.25;
    }
}
.style_toolTip__buKH8{	
	position: absolute;
	left: 50%;
	opacity: 0;
	visibility: hidden;
    line-height: 1;
	-webkit-transform: translateY(5px);
	        transform: translateY(5px);
}
*:hover > .style_toolTip__buKH8{
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0px);
	        transform: translateY(0px);
	transition: visibility 0.01s, opacity 0.3s 0.01s, -webkit-transform 0.3s 0.01s;
	transition: visibility 0.01s, opacity 0.3s 0.01s, transform 0.3s 0.01s;
	transition: visibility 0.01s, opacity 0.3s 0.01s, transform 0.3s 0.01s, -webkit-transform 0.3s 0.01s;
}
.style_toolTip__buKH8 .style_center__A5N_y{
	position: relative;
	background: var(--tooltip-bg);
	left: -50%;
    line-height: 1;
	color: var(--tooltip-text);
	padding: 10px 15px;
    white-space: nowrap;
	text-transform: none;
	font-weight: normal;
	font-size: 12px;
	border-radius: 5px;
}
.style_toolTip__buKH8 .style_scroller__vI94X {
	max-height: 210px;
	overflow: auto;
	overflow-x: hidden;
	line-height: 1.2;
}
.style_toolTipIcon__2DuRa {
	position: absolute;
	left: 0;
	color: var(--tooltip-bg);
	font-size: 18px;
    margin-left: -5px;
}
.style_top__28zjJ {
	margin-bottom: 8px;
	bottom: 100%;
}
.style_top__28zjJ .style_toolTipIcon__2DuRa {
	bottom: -11px;
}
.style_bottom__17RVD {
	top: 100%;
	margin-top: 11px;
}
.style_bottom__17RVD .style_toolTipIcon__2DuRa {
	top: -11px;
}
.style_right__1YK5G .style_center__A5N_y {
	left: -20px;
}
.style_toolTip__buKH8 .style_center__A5N_y a {
	border-bottom: 1px solid var(--tooltip-text);
}
.style_bsz__1r35c,
.style_bgImage__23fjS,
.style_bsz__1r35c img,
.style_videoWrap__2IVRE,
.style_video__1blfu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.style_bgImage__23fjS {
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: opacity 0.4s 0.2s;
}

.style_bgImage__23fjS.style_loading__2E9rM,
.style_bsz__1r35c img {
    opacity: 0;
}

.style_fa__BUcnp {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 32px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.style_fa__BUcnp i {
    vertical-align: middle;
}

.style_sizer__Q3Gp8 {
    padding-top: 50%;
}

.style_video__1blfu {
    object-fit: cover;
}

.style_gradientBar__6Y9uw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.style_svgFile__3lJ8D>div {
    display: flex;
}

.style_svgFile__3lJ8D:empty {
    display: none;
}
.style_formGroup__2806u {
  position: relative;
}
.style_optionContainer__2Klp2 {
  position: relative;
  width: 100%;
  margin-top: 12px;
  background-color: var(--layout-bg);
  border: 1px solid var(--input-border);
  border-radius: 4px;
  max-height: 430px;
  overflow: auto;
}
.style_optionWrapper__3zFtY {
  position: relative;
}
.style_controlElement__3lL7B {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
  margin-top: 12px;
}
.style_controlElement__3lL7B,
.style_searchBox__Bp_-T {
  background-color: var(--layout-bg);
  border-radius: 4px;
  padding: 8px 12px;
}
.style_controlElement__3lL7B {
  border: 1px solid var(--input-border);
}
.style_searchBox__Bp_-T {
  border-bottom: 1px solid var(--input-border);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}
.style_searchBox__Bp_-T .style_searchIcon__3Tdig {
  padding-right: 8px;
}
.style_searchInput__2ydIo {
  display: inline-block;
  width: 100%;
}
.style_searchInput__2ydIo::-webkit-input-placeholder,
.style_controlElement__3lL7B label {
  font-size: 14px;
  line-height: 1.25;
}
.style_searchInput__2ydIo::-webkit-input-placeholder,
.style_formGroup__2806u:not(.style_hasValue__1HngK) .style_controlElement__3lL7B label {
  color: #000000bd;
}
.style_icon__1_1QY {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 13px;
}
.style_icon__1_1QY svg {
  width: 13px;
  height: 13px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  transition: all 0.8s;
}
.style_icon__1_1QY.style_opened__3DPWZ svg {
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
}
.style_options__3F8KM {
  position: relative;
  padding: 8px 0;
}
.style_options__3F8KM .style_item__3DGDM {
  display: inline-block;
  width: 100%;
  min-height: 40px;
  padding: 0 16px;
  font-size: 14px;
  text-align: start;
}
.style_options__3F8KM .style_item__3DGDM:hover {
  background-color: #161f641a;
}
.style_optionContainer__2Klp2::-webkit-scrollbar {
  display: none;
}
.style_scrollBar__ErbWm {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  overflow: hidden;
}
.style_scrollBar__ErbWm::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  background-color: var(--input-text);
}

.style_skeleton__jNO7P {
    height: inherit;
    background-color: #4d4c4c;
    margin: 12px 0;
    border-radius: 4px;
}

.style_skeleton__jNO7P.style_title__18bep {
    height: 28px;
    margin-bottom: 10px;
}

.style_skeleton__jNO7P.style_subtitle__303z6 {
    height: 12px;
    margin-bottom: 16px;
}

.style_skeleton__jNO7P.style_image__2ejzK {
    height: 195px;
}

.style_skeleton__jNO7P.style_text__3y5BA {
    height: 12px;
}


.style_skeleton__jNO7P.style_width100__fuA_t {
    width: 100%;
}

.style_skeleton__jNO7P.style_width50__23AuU {
    width: 50%;
}

.style_logo__1KtXd{
    margin: 0;
    height: 100%;
}

.style_width35__1xDF9 {
    width: 35%;
}

.style_width15__72pxQ {
    width: 15%;
}

.style_skeleton__jNO7P.style_profile-circle__V8ysJ {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.style_cards__t7TFc {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -10px;
}

.style_cards__t7TFc .style_card__144eU {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
}

.style_cards__t7TFc .style_card__144eU>div {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.style_articles__KhABs.style_cards__t7TFc .style_card__144eU {
    width: 25%;
    height: 250px;
}

.style_themes__GVAEq.style_cards__t7TFc {
    overflow: auto;
    flex-wrap: nowrap;
}

.style_themes__GVAEq.style_cards__t7TFc .style_card__144eU {
    width: 18%;
    min-width: 18%;
    height: 360px;
}

/* animation */
@-webkit-keyframes style_pulse__2M3rO {
    50% {
        opacity: .5;
    }
}
@keyframes style_pulse__2M3rO {
    50% {
        opacity: .5;
    }
}

.style_animatepulse__1M0Qg {
    -webkit-animation: style_pulse__2M3rO 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
            animation: style_pulse__2M3rO 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.style_loader__3806T {
    margin-bottom: 54px;
}

.style_loader__3806T:nth-last-child() {
    margin-bottom: 0;
}

.style_loader__3806T .style_title__18bep {
    width: 20% !important;
}

/* Fullscreen css begin */
.style_fullScreen__cIsRt {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -12px;
}

/* Fullscreen css end */

@media (max-width: 1300px) {
    .style_themes__GVAEq.style_cards__t7TFc .style_card__144eU {
        width: 20%;
        min-width: 20%;
    }
}

@media (max-width: 1100px) {
    .style_themes__GVAEq.style_cards__t7TFc .style_card__144eU {
        width: 22%;
        min-width: 22%;
    }
}

@media (max-width: 1200px) {
    .style_title__18bep.style_width35__1xDF9 {
        width: 24%;
    }

    .style_articles__KhABs.style_cards__t7TFc .style_card__144eU {
        width: 33.33%;
        height: 180px;
    }

    .style_themes__GVAEq.style_cards__t7TFc .style_card__144eU {
        width: 24%;
        min-width: 24%;
    }
}

@media (max-width: 800px) {
    .style_width35__1xDF9 {
        width: 75%;
    }

    .style_articles__KhABs.style_cards__t7TFc .style_card__144eU {
        width: 50%;
        height: 160px;
    }

    .style_themes__GVAEq.style_cards__t7TFc .style_card__144eU {
        width: 35%;
        min-width: 35%;
    }
}
.style_center__4JUGO {
    max-width: 1520px;
    padding: 0 60px;
    margin: auto;
}

@media(max-width: 1440px) {
    .style_center__4JUGO {
        padding: 0 32px;
    }
}

@media (max-width: 550px) {
    .style_center__4JUGO {
        padding: 0 30px;
    }
}

@media (max-width: 420px) {
    .style_center__4JUGO {
        padding: 0 16px;
    }
}

.modal,
.dialogBoxOverlayWrap,
.dialogBoxOverlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.modal {
    position: fixed;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in;
}

.modal.show {
    opacity: 1;
    visibility: visible;
}

.modal.hide {
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s 0.4s ease-out;
}

.dialogBoxOverlayWrap {
    background: #000000a6;
    color: #0F2846;
    position: absolute;
}

.dialogBoxOverlay {
    position: absolute;
    overflow: auto;
    z-index: 1;
}

.dialogOpen {
    opacity: 1;
}

.dialogRemove {
    opacity: 0;
    transition: opacity 0.6s ease-out;
}

.dialogClose {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 15px 20px;
    background-color: transparent;
    z-index: 2;
}

.closeIcon {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
}

.closeText {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    position: relative;
    top: 2px;
}

.dialogClose:hover {
    color: #000
}

.dialogContent {
    position: relative;
}

.dialogItem {
    position: relative;
    padding: 16px;
    text-align: left;
    color: var(--layout-text);
    background-color: var(--layout-bg);
    cursor: pointer;
    border: 1px solid #0000;
    border-radius: 6px;
}

.dialogItem .link{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dialogItem:hover {
    border-color: var(--button-bg);
}

.dialogItem.active {
    background-color: var(--button-bg);
    color: #000;
}

.dialogItem.active .radio {
    background-color: var(--layout-bg);
    opacity: 1;
}

.dialogItem h4 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    padding-bottom: 4px;
}

.dialogItem .radio {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-color: var(--button-bg);
    opacity: .4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.dialogItem.padd h4 {
    padding-left: 35px;
}

@media (max-width: 550px) {
    .closeText {
        display: none;
    }

    .dialogClose {
        right: 0;
        background-color: #fff;
        top: 0;
    }

    .col {
        display: block;
        width: auto;
    }
}
.content {
  min-height: 100vh;
}

:global(.darkTheme) .heightBar {
  display: none;
}

:global(.darkTheme.showHeight) .heightBar {
  display: block;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0px;
  transition: background-color 0.8s;
  border-bottom: 1px solid var(--header-border);
  z-index: 99;
}

.campaign.header,
.register.header {
  background-color: var(--dark-green);
}

:global(.articlePage) .header,
.header.fixedHeader,
:global(.fixedHeader) {
  background-color: var(--layout-bg);
}

.headerLoader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 64px;
}

.headerLoader .logo {
  position: relative;
  width: 120px;
  height: 50px;
}

.headerLoader .menus {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 220px;
}

.headerLoader .menus>div {
  width: 160px;
  margin: 0;
}

.headerLoader .button,
.headerLoader .profile {
  position: relative;
  right: 0;
  top: -13px;
}

.headerLoader .profile {
  top: -6px;
}

.headerLoader .profile>div {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.headerLoader .button {
  width: 220px;
  height: 50px;
}

.headerLoader .button>div {
  border-radius: 50px;
}

.register.header .hamburgerMenu {
  display: none;
}

.floatingHeader {
  border-bottom-width: 0px;
  background: linear-gradient(180deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
  z-index: 9;
  position: absolute;
}

.floatingHeader+div {
  display: none;
}

:global(.black-header) .header {
  background-color: var(--layout-bg);
}

.headerCenter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerMenu .linkItem {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  padding: 30px 0;
  color: var(--nude);
}

.headerMenu .linkItem .chevronArrow {
  display: none;
}

.headerMenu .link {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 4px;
  margin-left: 15px;
  border-bottom: 2px solid transparent;
}

.headerMenu .link:first-child {
  margin-left: 0;
}

.headerMenu .link:hover {
  border-bottom-color: var(--layout-text);
}

.headerMenu .link.active {
  font-weight: 700;
  border-bottom-color: var(--layout-text);
}

.submenu {
  position: absolute;
  left: -15px;
  top: 75px;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  min-width: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.74);
}

.header .linkItem:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.submenu .submenuItem {
  padding-top: 10px;
  display: block;
  font-weight: 700;
  color: var(--nude);
}

.submenu .submenuItem:first-child {
  padding-top: 0;
}

.submenu .submenuItem span {
  border-bottom: 2px solid transparent;
  transition: border 0.4s;
  padding-bottom: 5px;
  display: inline-block;
}

.submenu .submenuItem:hover span,
.submenu .submenuItem.active span {
  border-bottom-color: var(--layout-text);
}

.logo {
  display: inline-block;
  vertical-align: middle;
  min-width: 200px;
}

.logo .tabletLogo,
.logo .mobileLogo {
  display: none;
}

.logo img {
  height: auto;
  width: 240px;
}

.logo .mobileLogo img {
  width: 150px;
}


/* Footer style starts */

.footer {
  margin-top: 4.32vw;
  padding: 56px 0px;
  color: var(--nude);
  position: relative;
}

.register.footer {
  background-color: var(--dark-green);
}

.footer>div:first-child {
  max-width: 571px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.footer .bg_image {
  background-size: contain;
  background-position: bottom;
}


.footer .inner_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer .inner_wrap::after {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 100%;
  left: 61%;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--sushi-green);
}

.campaignPage.footer {
  background-color: var(--grey);
  border-top: 1px solid var(--layout-text);
}

.footerCenter {
  max-width: 1100px;
  margin: auto;
}

.footer .logo img {
  height: 40px;
}

.footerLeft {
  padding-right: 40px;
  width: 61%;
  display: flex;
  position: relative;
}

.social_link_wrap {
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  left: 39px;
}

.footerRight {
  padding-left: 40px;
  width: 39%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footerRight .address,
.footerRight .greet_text {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}

.footerRight .address {
  padding-top: 32px;
}

.footer_logo {
  position: absolute;
  top: 0;
  right: 0;
}

.brand_image_wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
}

.brand_image:hover {
  opacity: 0.7;
}

.brand_image+.brand_image {
  padding-left: 24px;
}

.brand_image img {
  max-height: 37px;
}

.footer_logo img {
  height: 110px;
}

.brand_image .sizer {
  padding-top: 10%;
}


.supportImage {
  width: 200px;
}

.footerLeft .footerCol {
  padding-right: 39px;
}

.footerLeft .footerCol:last-child {
  padding-right: 0;
}

.footerCol.flex_wrap {
  position: relative;
}

.footerTitle {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  text-transform: uppercase;
  line-height: 2;
}

.footerLinkItem {
  font-size: 16px;
  line-height: 2;
  font-weight: 500;
  text-transform: capitalize;
}

.relative {
  position: relative;
}

.footerLinkItem:hover {
  color: var(--link-hover);
}

.footerText {
  position: relative;
}

.footerText img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -18px;
  opacity: var(--chart-opacity);
  transition: opacity 0.4s;
}

.footerText p {
  font-size: 15px;
  padding: 17px 0 18px 0;
  max-width: 322px;
  margin: 23px 0 60px;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0.1em;
}

.socials {
  margin: 0 -15px;
}

.campaignPage .socials {
  margin: 0;
  display: flex;
  align-items: center;
}

.campaignPage .socialItem {
  margin: 0;
}

.campaignPage .socialItem+.socialItem {
  padding-left: 24px;
}

.socialItem {
  margin: 10px 15px 0;
  display: inline-block;
  font-size: 24px;
}

.socialItem:first-child {
  margin-left: 0;
}

.companyRegistration {
  margin-top: 60px;
  font-size: 10px;
}

.profileSection>* {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.profileSection .icon {
  position: relative;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.profileSection .search svg {
  width: 32px;
  height: 32px;
}

.profileSection .search:hover {
  color: var(--link-hover);
}

.profileSection .letter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--button-bg);
  color: var(--button-text);
  font-weight: 600;
  text-transform: uppercase;
}

:global(.profile) .profileSection .icon {
  border: 2px solid var(--layout-text);
}

.content :global(.section):last-child {
  padding-bottom: 60px;
}

.hamburgerMenu {
  display: none;
}

.authorize {
  position: relative;
  border-top: 1px solid var(--footer-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorize .container {
  display: grid;
  gap: 10px;
  background-color: var(--button-bg);
  padding: 50px 90px;
  border-radius: 18px;
  color: #000;
  min-width: 520px;
  z-index: 2;
  text-align: center;
}

.authorize .spinner {
  position: relative;
  height: 20px;
}

.authContainer {
  color: #fff;
  background-color: #000;
  text-align: center;
  margin: auto;
}

.authContainer .authContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.authContent .spinner {
  position: relative;
  height: 50px;
}

/* Mobile Hambuger menu begin */
.mobileMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: auto;
  z-index: 9998;
  transform: translateY(100%);
  transition: transform 0.8s;
}

.mobileMenu.show {
  transform: translateY(0);
}

.mobileMenu .container {
  position: absolute;
  color: #fff;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
}

.mobileMenu .closeBtn {
  position: fixed;
  padding: 30px 26px;
}

.mobileMenu .closeBtn svg {
  width: 18px;
  height: 18px;
}

.mobileMenu .items {
  position: relative;
  display: grid;
  gap: 32px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.2;
}

.mobileMenu .items>a {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--link-border);
}

.mobileMenu .linkItem {
  position: relative;
  padding-bottom: 28px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--footer-border);
}

.mobileMenu .linkItem .link {
  display: block;
}

.mobileMenu .linkItem .chevronArrow {
  position: absolute;
  top: -5px;
  right: -10px;
  transform: rotate(270deg);
  padding: 10px;
}

.mobileMenu .linkItem .chevronArrow>div {
  width: 16px;
  height: 16px;
}

.mobileMenu .submenu .submenuItem.active span {
  border-bottom-color: transparent;
}

.mobileMenu .submenu {
  position: static;
  text-transform: none;
  max-height: 0;
  transition: opacity 0.4s 0s, visibility 0.4s 0s, max-height 0.4s 0s;
  padding-left: 0;
  padding-right: 0;
}

.mobileMenu .showSubMenu .submenu {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s 0.4s, visibility 0.4s 0.4s max-height 0.4s 0.5s;
}

.mobileMenu .linkItem.hasSubmenu {
  padding-bottom: 0;
}

.mobileMenu .showSubMenu .chevronArrow {
  transform: rotate(360deg);
}

/* Mobile Hambuger menu end */

/* Welcome Popup Begin */
.welcomePopup {
  position: relative;
  padding: 0 16px;
}

.welcomePopup .inner_wrap {
  position: relative;
  min-height: 100vh;
}

.welcomePopup .text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: var(--layout-bg);
  border-radius: 4px;
  color: var(--layout-text);
  padding: 80px;
  max-width: 680px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px 0px rgba(0, 0, 0, 0.20);
}

.welcomePopup h4 {
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.05;
  font-weight: 700;
  color: var(--nude);
}

.welcomePopup p {
  margin-top: 16px;
  font-size: 16px;
  color: var(--chalke-off-white)
}

.welcomePopup .button_wrap {
  padding-top: 32px;
}

.welcomePopup .button_wrap button {
  margin-right: 24px;
}

/* Welcome Popup Css */

/* Footer Podium logo begin */
.podiumLogo {
  position: absolute;
  right: 60px;
  bottom: 0;
}

.podiumLogo .logo {
  text-align: end;
}

.footerPodiumLogo .logo img,
.podiumLogo .logo img {
  height: 52px !important;
}

.podiumHeaderLogo img {
  height: 45px;
}

/* Footer Podium logo end */

.blackBar.header {
  background-color: #000;
}

.signInButton {
  display: flex;
  gap: 16px;
}

@media(min-width: 1920px) {
  .footer {
    margin-top: 83px;
  }
}

@media(max-width: 1300px) {
  .footerLeft .footerCol {
    padding-right: 35px;
  }
}

@media (max-width: 1200px) {
  .footerLeft .footerCol {
    padding-right: 30px;
  }

  .footer .inner_wrap::after {
    left: 56%;
  }

  .footerLeft {
    padding-bottom: 52px;
    width: 58%;
    justify-content: space-between;
  }

  .footerRight {
    width: 45%;
  }

  .footerCol.flex_wrap {
    position: static;
  }

  .social_link_wrap {
    left: 0;
  }
}


@media (max-width: 1024px) {
  .header {
    padding: 16px 0;
  }

  .logo .desktopLogo {
    display: none;
  }

  .logo .tabletLogo {
    display: inline;
  }

  .footerLeft .footerCol {
    padding-right: 28px;
  }

  .welcomePopup .text {
    max-width: 716px;
  }

  .header .logo {
    min-width: 0;
  }

  .footerText img {
    left: -16px;
  }

  .podiumLogo {
    right: 40px;
  }

  .podiumHeaderLogo img {
    height: 40px;
  }
}

@media(max-width: 1023px) {
  .welcomePopup .text {
    padding-left: 6vw;
    padding-right: 6vw;
  }
}

@media(max-height: 900px) {
  .logo img {
    height: auto;
  }
}

@media(max-width: 900px) {
  .footer {
    padding-top: 50px;
  }


  .footer_logo {
    position: static;
  }

  .footer .inner_wrap::after {
    display: none;
  }

  .footer .inner_wrap {
    display: block;
  }

  .footerLeft {
    width: 100%;
    flex-wrap: wrap;
    justify-content: initial;
    padding-right: 0;
  }

  .footerLeft .footerCol {
    padding-top: 32px;
    padding-left: 0;
    padding-right: 24px;
  }

  .footerRight {
    text-align: left;
    padding-left: 0;
    padding-top: 32px;
    width: 100%;
  }

  .brand_image_wrap {
    justify-content: flex-start;
    max-width: 260px;
  }

  .brand_image+.brand_image {
    padding-left: 20px;
  }

  .welcomePopup .text {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 850px) {
  .footer {
    padding-top: 44px;
  }

  .companyRegistration,
  .socials {
    margin-top: 40px;
  }

  .footerText p {
    margin: 30px 0 0;
  }
}

@media(max-width: 820px) {
  .header {
    padding: 12px 0;
  }

  .footer {
    padding-top: 38px;
  }


  .footerLeft .footerCol {
    padding-right: 20px;
  }

  .brand_image+.brand_image {
    padding-left: 16px;
  }

  .welcomePopup .text {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .welcomePopup h4 {
    font-size: 37px;
  }
}

@media (max-width: 800px) {
  .footer {
    padding-top: 32px;
  }

  .logo .tabletLogo {
    display: none;
  }

  .logo .mobileLogo {
    display: inline;
  }

  .footerText p {
    max-width: 400px;
  }

  .footerRight {
    overflow: visible;
    width: 100%;
    max-width: 500px;
  }

  .footer .logo img {
    height: 40px;
  }

  .headerMenu {
    display: none;
    cursor: pointer;
  }

  .header .clearClass {
    display: none;
  }

  .headerCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .signInButton a {
    padding: 8px;
    font-size: 14px;
  }

  .hamburgerMenu {
    display: inline-block;
    width: 16px;
    height: 14px;
    position: relative;
    margin: 3px 2px;
    cursor: pointer;
    cursor: pointer;
  }

  .hamburgerMenu div {
    opacity: 1;
    transition: opacity 0.4s;
  }

  .hamburgerMenu div,
  .hamburgerMenu::before,
  .hamburgerMenu::after {
    background-color: var(--layout-bg-revert);
    width: 100%;
    height: 2px;
  }

  .hamburgerMenu::before,
  .hamburgerMenu::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    transition-duration: 0.3s, 0.3s, 0.3s;
    /* transition-delay: .3s, 0s, 0s; */
  }

  .hamburgerMenu::after {
    top: auto;
    bottom: 0;
  }

  .hamburgerMenu.toggled:before {
    transform: rotate(225deg);
  }

  .hamburgerMenu.toggled:after {
    transform: rotate(-225deg);
  }

  .hamburgerMenu.toggled div {
    opacity: 0;
  }

  .hamburgerMenu.toggled::before,
  .hamburgerMenu.toggled::after {
    transition-delay: 0s, 0.3s;
    top: 50%;
    left: 0;
    bottom: auto;
  }

  .profileSection>* {
    margin: 0;
  }

  .profileSection .icon {
    margin-left: 8px;
  }

  .profileSection .search svg {
    width: 16px;
    height: 16px;
  }

  .footerText p {
    padding-right: 120px;
    margin: 30px 0 40px;
  }

  .supportImage {
    margin: 30px 0 40px;
  }

  .podiumLogo {
    position: relative;
    padding: 40px 40px 0;
  }

  .podiumLogo .logo {
    text-align: start;
  }

  .podiumHeaderLogo img {
    height: 35px;
  }
}

@media(max-width: 740px) {
  .footer {
    padding-top: 28px;
  }


  .footerLeft .footerCol {
    padding-right: 16px;
  }

  .brand_image+.brand_image {
    padding-left: 12px;
  }

  .welcomePopup .text {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .welcomePopup h4 {
    font-size: 34px;
  }
}

@media(max-width: 650px) {
  .welcomePopup .button_wrap button {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  .welcomePopup .button_wrap button+button {
    margin-top: 24px;
  }
}

@media (max-width: 550px) {
  .footer {
    padding-top: 24px;
  }

  .logo {
    min-width: 100px;
  }

  .footerText p {
    padding: 10px 120px 10px 0;
  }

  .supportImage {
    margin: 50px 0 40px;
  }

  .podiumHeaderLogo img {
    height: 30px;
  }

  .welcomePopup .text {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .welcomePopup h4 {
    font-size: 33px;
  }
}

@media (max-width: 450px) {
  .header {
    padding: 8px 0;
  }

  .footer {
    margin-top: 0;
  }

  .footerRight {
    flex-wrap: wrap;
  }

  .footerLeft {
    display: block;
  }

  .footerLeft .footerCol {
    white-space: wrap;
    display: inline-block;
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .footerLeft .footerCol:nth-child(odd) {
    padding-left: 0;
  }

  .mobileMenu .container {
    padding: 0 16px;
  }

  .footer .logo img {
    height: 36px;
  }

  .welcomePopup .text {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 380px) {
  .footerText p {
    padding-right: 40px;
  }
}
.bsz,
.bgImage,
.bsz img,
.videoWrap,
.video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.bgImage {
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: opacity 0.4s 0.2s;
}

.bgImage.loading,
.bsz img {
    opacity: 0;
}

.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 32px;
    transform: translate(-50%, -50%);
}

.fa i {
    vertical-align: middle;
}

.sizer {
    padding-top: 50%;
}

.video {
    object-fit: cover;
}

.gradientBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.svgFile>div {
    display: flex;
}

.svgFile:empty {
    display: none;
}
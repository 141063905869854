.formGroup {
  position: relative;
}
.optionContainer {
  position: relative;
  width: 100%;
  margin-top: 12px;
  background-color: var(--layout-bg);
  border: 1px solid var(--input-border);
  border-radius: 4px;
  max-height: 430px;
  overflow: auto;
}
.optionWrapper {
  position: relative;
}
.controlElement {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
  margin-top: 12px;
}
.controlElement,
.searchBox {
  background-color: var(--layout-bg);
  border-radius: 4px;
  padding: 8px 12px;
}
.controlElement {
  border: 1px solid var(--input-border);
}
.searchBox {
  border-bottom: 1px solid var(--input-border);
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}
.searchBox .searchIcon {
  padding-right: 8px;
}
.searchInput {
  display: inline-block;
  width: 100%;
}
.searchInput::-webkit-input-placeholder,
.controlElement label {
  font-size: 14px;
  line-height: 1.25;
}
.searchInput::-webkit-input-placeholder,
.formGroup:not(.hasValue) .controlElement label {
  color: #000000bd;
}
.icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  height: 13px;
}
.icon svg {
  width: 13px;
  height: 13px;
  transform: rotate(270deg);
  transition: all 0.8s;
}
.icon.opened svg {
  transform: rotate(-270deg);
}
.options {
  position: relative;
  padding: 8px 0;
}
.options .item {
  display: inline-block;
  width: 100%;
  min-height: 40px;
  padding: 0 16px;
  font-size: 14px;
  text-align: start;
}
.options .item:hover {
  background-color: #161f641a;
}
.optionContainer::-webkit-scrollbar {
  display: none;
}
.scrollBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  overflow: hidden;
}
.scrollBar::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  background-color: var(--input-text);
}

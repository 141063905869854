.formGroup {
    position: relative;
}

input[type="text"].textbox,
.textbox {
    font-size: 16px;
    padding: 15px;
    width: 100%;
    background: transparent;
    text-align: left;
    background-color: var(--input-bg);
}

:global .PhoneInput {
    font-size: 16px;
    padding: 15px;
    width: 100%;
    background: transparent;
    text-align: left;
}

.inputDisplay .textbox {
    line-height: 1.65;
    padding: 15px 0 10px;
}

.formField,
.otpInput {
    border: 1px solid var(--input-border);
    position: relative;
    z-index: 2;
    margin-top: 14px;
    border-radius: 5px;
    transition: border-color 0.4s;
}

.inputError {
    border-color: var(--input-error);
}

.inputFocus {
    border-color: var(--input-focus);
}

.noLabel .textbox {
    padding: 8px 15px;
}

textarea.textbox {
    height: 200px;
}

.selectBox {
    transition: opacity 0.4s;
}

.selectBox select {
    opacity: 0;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.selectBox .fa,
.selectBox .spinner {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 18px;
}

.selectBox .fa {
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.selectDisabled {
    opacity: 0.3;
}

.inputFetching .textbox {
    padding: 15px;
}

.inputFetchingLabel+div {
    transform: translateY(-50%);
    right: 15px;
    margin: 0;
    left: auto;
}

.checkbox {
    cursor: pointer;
}

.checkbox:hover {
    color: var(--link-hover);
}

.checkbox>* {
    display: inline-block;
    vertical-align: middle;
}

.checkboxSquare {
    display: inline-block;
    margin-right: 15px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--checkbox-border);
    position: relative;
    border-radius: 5px;
    transition: background-color 0.4s;
}

.checkboxText>* {
    vertical-align: middle;
}

.checkboxText {
    line-height: 1.5;
}

.controlLabel {
    text-align: left;
}

.inputDisplay .controlLabel {
    font-weight: 700;
}

.checkboxInput:checked+.checkboxSquare {
    background-color: var(--checkbox-border);
}

.checkboxInput+.checkboxSquare::before {
    content: '';
    display: block;
    z-index: 4;
    width: 10px;
    height: 4px;
    border-left: 2px solid var(--button-text);
    border-bottom: 2px solid var(--button-text);
    transform: translate(-50%, -68%) rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: opacity 0.4s;
    opacity: 0;
}

.checkboxInput:checked+.checkboxSquare::before {
    opacity: 1;
}

.checkboxText a {
    position: relative;
}

.checkboxText a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: var(--link-hover);
}

.checkboxText a:hover::after {
    animation: border-animation .8s;
}

.button {
    font-size: 16px;
    display: inline-block;
    text-align: center;
    position: relative;
    border: 1px solid;
    padding: 17px 24px;
    border-radius: 8px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: var(--nude);
    color: var(--dark-green);
}

.button.buttonFull {
    width: 100%;
    padding: 15px 30px;
    font-size: 1.15em;
    font-weight: 700;
}

.button:not(.buttonDisabled):hover,
.buttonInvert {
    background-color: transparent;
    color: var(--nude);
}

.button.outlineBtn:hover,
.buttonInvert:not(.buttonDisabled):hover {
    background-color: var(--button-bg);
    color: var(--button-text);
}

.button.outlineBtn {
    background-color: #0000;
    color: var(--button-bg);
}

.buttonDisabled {
    opacity: 0.5;
}

.buttonDisabled>span {
    vertical-align: middle;
}

.buttonQuestion {
    margin-left: 5px;
}

.button .icon_label,
.button .icon_svg {
    display: inline-block;
    vertical-align: middle;
}

.button .icon_left {
    padding-right: 10px;
}

.button .icon_right {
    padding-left: 10px;
}

.required {
    color: inherit;
}

.helpBlock {
    color: var(--input-error);
    position: absolute;
    right: 0;
    top: 100%;
    font-size: 13px;
    z-index: 4;
    white-space: nowrap;
    padding: 10px 15px 10px 10px;
    line-height: 1;
}

.tags {
    margin: 0 -3px;
    max-height: 95px;
    overflow: auto;
}

.tag {
    display: inline-block;
    padding: 4px 10px;
    margin: 3px;
    border-radius: 5px;
    background-color: var(--tag-bg);
    color: var(--input-bg);
    font-size: 13px;
}

.tag>* {
    vertical-align: middle;
}

.tag>button {
    margin-left: 10px;
}

.asyncInputTag {
    z-index: 10;
}

input[type="text"].autcompleteInput {
    width: 100px;
    padding: 5px 0 0 5px;
    border: 0;
}

.autocompleteLoading {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    width: 30px;
}

.autocompleteResult {
    position: absolute;
    left: -1px;
    margin-top: 3px;
    top: 100%;
    right: -1px;
    max-height: 339px;
    background-color: var(--default-bg);
    border: 1px solid var(--input-border);
    overflow: auto;
    border-radius: 5px;
}

.autocompleteResultItem {
    border-top: 1px solid var(--autocomplete-border);
    display: block;
    padding: 12px 15px;
    font-size: 12px;
    width: 100%;
    text-align: left;
}

.autocompleteResultItem:hover,
.autocompleteResultActive {
    background-color: var(--autocomplete-hover);
    color: var(--text-hover);
}

.autocompleteResultItem:first-child {
    border-top: 0;
}

.recaptcha {
    text-align: center;
}

.recaptcha>div {
    display: inline-block;
}

.otpInputContainer {
    flex-direction: row;
    margin: 0 -10px;
}

.otpInputContainer>* {
    flex: 1;
    padding: 0 10px;
}

.otpInput {
    width: 100% !important;
    padding: 15px 0 !important;
    font-size: 24px;
    margin-top: 15px;
}

.otpError {
    color: var(--input-error);
    text-align: right;
    padding: 5px 0 5px 0;
}

button.outlineBtn {
    border-color: #fff;
    color: #fff;
    background-color: #0000;
}

button.button.outlineBtn:hover {
    border-color: var(--button-bg);
    background-color: var(--button-bg);
    color: var(--button-text);
}

.filterBox {
    position: relative;
    border: 1px solid var(--layout-bg-revert);
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
}

.filterBox.toggled {
    background-color: var(--on-surface-disabled);
}

.filterBox .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 158px;
}

.filterBox .options {
    position: absolute;
    z-index: 99;
    background-color: #000;
    width: 100%;
    left: 0;
    padding: 8px 16px;
    list-style: none;
    margin-top: 10px;
    max-height: 220px;
    overflow: auto;
}

.filterBox .options::-webkit-scrollbar {
    width: 4px;
}

.filterBox .options::-webkit-scrollbar-track {
    background-color: #ffffff30;
}

.filterBox .options::-webkit-scrollbar-thumb {
    background-color: rgb(232, 229, 229);
}

.filterBox .label {
    transform: rotate(0deg);
    transition: transform .4s;
}

.filterBox .label .rotate {
    transform: rotate(180deg);
    transition: transform .4s;
}

.filterBox .label label {
    font-size: 16px;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.filterBox .options .optionItem {
    display: inline-block;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    color: var(--layout-text);
    border-bottom: 1px solid var(--on-surface-disabled);
    padding: 8px 0;
}

.filterBox .options .optionItem:last-child {
    border-color: #0000;
}

/* Profile Picture Uploader Begin */
.profilePicture {
    position: relative;
    width: 130px;
    height: 130px;
    min-width: 130px;
    background-color: var(--nude);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}

.profilePicture.customUpload {
    pointer-events: none;
}

.profilePicture .uploadIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--on-primary-medium-emphasis);
    z-index: 1;
    color: var(--link-hover);
    transition: opacity .4s;
    opacity: 0;
    visibility: hidden;
}

.profilePicture .uploadIcon input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
}

.profilePicture:hover .uploadIcon {
    opacity: 1;
    visibility: visible;
}

.profilePicture .uploadIcon svg {
    width: 60px;
    height: 60px;
}

.profilePicture .picture svg {
    width: 80px;
    height: 80px;
}

.profilePicture .picture .letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--button-text);
    font-size: 130px;
}

.profileText {
    font-size: 16px;
    line-height: 1.5;
}

/* Profile Picture Uploader Css */

/* Multiple Select css Begin*/

.formGroup.boldLabel .controlLabel {
    font-weight: 700;
}

.upperCaseLabel {
    text-transform: uppercase;
    line-height: 1.2;
}

.formGroup.boldLabel .controlLabel .required {
    padding-right: 3px;
}

.selectMultipleBox {
    position: relative;
}

.selectMultipleBoxList .checkbox {
    display: block;
    padding: 8px 15px;
    transition: background-color 0.4s;
}

.selectMultipleBoxList .checkbox:hover {
    background-color: var(--multiselect-hover);
}

.selectMultipleBoxList .checkbox:first-child {
    margin-top: 0;
}

.selectMultipleBoxList {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: var(--layout-bg);
    margin-top: 0;
    box-shadow: 0 3px 4px #bbb5b524, 0 3px 3px #bbb5b51f, 0 1px 8px var(--dropdown-shadow);
    z-index: 11;
}

.selectMultipleBoxList .saveBtn {
    background-color: var(--dark-green);
    color: var(--nude);
    border-color: var(--dark-green);
}

.selectMultipleBoxList .saveBtn:hover {
    color: var(--dark-green);
}

.selectMultipleBoxList .cancelBtn {
    background-color: transparent;
    color: var(--dark-green);
}

.selectMultipleBoxList .cancelBtn:hover {
    background-color: var(--dark-green);
    color: var(--nude);
}

.selectMultipleBoxOptions {
    margin: -4px;
}

.selectMultipleBoxOption {
    position: relative;
    cursor: pointer;
    text-align: start;
    background-color: var(--button-bg);
    color: var(--button-text);
    line-height: 1.2;
    display: inline-block;
    padding: 9px 26px 9px 16px;
    border-radius: 20px;
    margin: 4px;
}

.selectMultipleBoxOptionFa {
    position: absolute;
    right: 10px;
    top: 10px;
}

.selectMultipleBoxPrompt {
    color: #999;
}

.selectMultipleBox .fa {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .4s;
    font-size: 20px;
}

.selectMultipleBox .fa.rotate {
    transform: translateY(-50%) (-180deg);
}

.selectMultipleBoxTrigger {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.selectMultipleBoxListScroller {
    position: relative;
    max-height: 260px;
    padding: 8px 0;
    overflow: auto;
}

.selectMultipleBoxListScroller .checkboxSquare {
    position: absolute;
    margin: auto;
}

.selectMultipleBoxListScroller .checkboxText {
    padding-left: 35px;
}

.selectMultipleBoxButtons {
    text-align: right;
    border-top: 1px solid var(--input-border);
    background-color: 1px solid var(--layout-bg);
    padding-top: 15px;
}

.selectMultipleBoxButtons .button:first-child {
    margin-right: 15px;
}

.selectMultipleBoxButtons .button {
    padding: 8px 30px;
}

.selectMultipleBoxOther {
    padding-top: 5px;
}

.selectMultipleBoxOther,
.selectMultipleBoxOther input {
    background-color: var(--layout-bg);
    color: var(--layout-text);
    border-radius: inherit;
}

/* Multiple Select css End*/

/* save button css begin */
.infoButton,
.playButton {
    display: block;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1;
    color: var(--layout-bg);
    border-radius: 4px;
    min-width: 98px;
}

.infoButton>*,
.playButton>* {
    vertical-align: middle;
    display: inline-block;
}

.infoText {
    margin-left: 8px;
}

.playButton {
    background-color: var(--layout-bg-revert);
    border-radius: 4px;
    border-color: var(--layout-bg-revert);
}

.infoButton {
    background-color: var(--opacity-bg);
    color: var(--layout-bg-revert);
    border-color: var(--opacity-bg);
}

.playButton:hover,
.infoButton:hover {
    color: var(--layout-text) !important;
}

.infoButton svg {
    height: 22px;
}

.buttonLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* save button css end */

/* Radio button css begin */
.checkboxGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-top: 12px;
}

.checkboxGroup.scrollable {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0;
    padding: 10px;
}

.checkboxGroup .checkbox {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.checkboxGroup.scrollable>.checkbox:first-child {
    padding-top: 0;
}

.checkboxGroup.scrollable>.checkbox:first-child .checkboxTitle {
    padding-top: 0;
}

.checkboxGroup .inlineCheck.checkbox {
    width: auto;
    padding-right: 15px;
}

.checkboxSelectorWrap {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 45px;
    margin-right: 10px;
    border: 1px solid var(--input-border);
    transition: border .4s;
}

.checkSelect {
    position: absolute;
    width: 80%;
    height: 80%;
    background-color: var(--sushi-green);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s;
}

.checkboxSelector {
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    color: #151c4e;
}

.checkboxGroup .checkboxLabel {
    cursor: pointer;
}

.checkboxGroup .checkboxLabel span {
    display: inline-block;
    vertical-align: middle;
}

.checkboxInput:checked+.checkboxLabel .checkboxSelector {
    display: block;
}

.checkboxTitle {
    font-weight: bold;
    display: block;
    padding: 10px 0;
}

/* 
.checkbox.checked .checkboxSelectorWrap {
    border: 6px solid var(--radio-select);
} */

.checkbox.checked .checkboxSelectorWrap .checkSelect {
    opacity: 1;
    visibility: visible;
}

/* Radio button css end */

@media (max-width: 1100px) {
    body .formGroup+.othersInput {
        width: 100%;
        padding: 0;
    }

    .helpBlock {
        top: auto;
        bottom: -20px;
        transform: none;
        padding: 0;
    }
}

@media(max-height: 900px) {
    .button {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 450px) {
    .otpInputContainer {
        margin: 0 -5px;
    }

    .otpInputContainer>* {
        padding: 0 5px;
    }
}

@keyframes border-animation {
    0% {
        width: 0
    }

    20% {
        width: 0
    }

    to {
        width: 100%
    }
}